import React from 'react'
import { Gpt } from "react-gpt-ads";
import useScreenSize from '../../hocs/useScreenSize';

const AdsFooter = () => {
  const { width } = useScreenSize();

  return (
    <div id='banner-ad-970x66'>
        {
          process.env.REACT_APP_REGION === 'latam' ?
            width > 768 ? 
              <Gpt 
                adUnit="/8211/disneylatino/tickets"
                name="banner-ad-970x66"
                size={[970, 66]}
              />
            :
              <Gpt 
                adUnit="/8211/disneylatino/tickets"
                name="banner-ad-970x66"
                size={[320, 50]}
              />
          :
            width > 768 ? 
              <Gpt 
                adUnit="/8211/disneybr/tickets"
                name="banner-ad-970x66"
                size={[970, 66]}
              />
            :
              <Gpt 
                adUnit="/8211/disneybr/tickets"
                name="banner-ad-970x66"
                size={[320, 50]}
              />
        }
    </div>
  )
}

export default AdsFooter