import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { GeoContext } from '../../context/Geotargeting';
import StickyBox from "react-sticky-box";
import * as SC from "./styled";

const ZOOM_DEFAULT = 12;

const Marker  = ({ className = "", text, icon, handleMouseEnter = ()=>null, handleMouseLeave = ()=>null }) => { 
return (
    <SC.MarkerCont 
      className={className} 
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave}
    >
      {icon}{text}
    </SC.MarkerCont>
  );
};

const Map = ({data, getThreateInfo, setFullscreen, fullscreen, activeCinema, setActiveCinema, activeGeo, resetLocationFilters}) => {
  const [zoom, setZoom] = useState(ZOOM_DEFAULT);
  const [mapReference, setMapReference] = useState(null);
  const [mapsReference, setMapsReference] = useState(null);
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 961px)").matches);
  const idleEventRef = useRef(null);
  const resizeEventRef = useRef(null);
  const { loaded, latitude, longitude} = useContext(GeoContext);

  const fitBounds = (maps, map) => {
    const bounds = new maps.LatLngBounds();
      if(data.showtime)data.showtime.forEach((place) => {
        let threate = getThreateInfo(place.theatersId)
        bounds.extend(new maps.LatLng(
          threate.lat,
          threate.lng,
        ));
      });

      if(activeGeo && loaded){
        bounds.extend(new maps.LatLng(
          latitude,
          longitude
        ));
      }
      map.fitBounds(bounds);
      bindResizeListener(map, maps, bounds)
  }

  useEffect(() => {
    let mediaQuery = window.matchMedia("(min-width: 961px)");
    const listener = () => setIsDesktop(mediaQuery.matches);
    mediaQuery.addEventListener("change",listener);
    return () => mediaQuery.removeEventListener("change",listener);
  }, []);

  useEffect(() => {
    if( mapReference && mapsReference ){
      fitBounds(mapsReference, mapReference);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGeo, loaded, data, fullscreen]);

  const handleClickChild = (key) => {
    if((key!== "me") && (parseInt(key) !== activeCinema))
      setActiveCinema(parseInt(key))
    else
      setActiveCinema(-1)
  }
/*
  const getMapBounds = (maps) => {
    const bounds = new maps.LatLngBounds();
    if(data.showtime)data.showtime.forEach((place) => {
      let threate = getThreateInfo(place.theatersId);
      bounds.extend(new maps.LatLng(
        threate.lat,
        threate.lng,
      ));
    });

    if(activeGeo && loaded){
      bounds.extend(new maps.LatLng(
        latitude,
        longitude
      ));
    }
    return bounds;
  };
  */
  const bindResizeListener = (map, maps, bounds) => {
    if(idleEventRef.current)
      maps.event.removeListener(idleEventRef.current)
    if(resizeEventRef.current)
      maps.event.removeListener(resizeEventRef.current)

    idleEventRef.current = maps.event.addDomListenerOnce(map, 'idle', () => {
      resizeEventRef.current = maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds,30);
      });
    });
  };

  const apiIsLoaded = (map, maps) => {
    setMapReference(map);
    setMapsReference(maps);
    //const bounds = getMapBounds(maps);
    //map.fitBounds(bounds);
    //bindResizeListener(map, maps, bounds);
    fitBounds(maps, map);
  };

  const handleFullScreen = () => {
    setFullscreen();
  }

  const handleChange = (data) => {
    if(data.zoom !== zoom)
      setZoom(data.zoom > 19 ? 19 : data.zoom);
  }

  const content = (
  <>
    <SC.ZoomInIcon fullscreen={fullscreen.open} onClick={()=>setZoom(zoom+1)}/>
    <SC.ZoomOutIcon fullscreen={fullscreen.open} onClick={()=>setZoom(zoom !==0 ? zoom-1 : 0)}/>
    { !activeGeo && loaded && <SC.LocationIcon fullscreen={fullscreen.open} onClick={()=>resetLocationFilters()}/>}
    <SC.FullScreenIcon fullscreen={fullscreen.open} onClick={handleFullScreen}/>
    <GoogleMapReact className="gMapsContainer"
      bootstrapURLKeys={{ key: process.env.REACT_APP_GMAPS_API_KEY }}
      defaultCenter={{lat: 0, lng:0 }}
      defaultZoom={ZOOM_DEFAULT}
      zoom={zoom}
      options={{ fullscreenControl: false, zoomControl: false }}
      onChildClick={handleClickChild}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
      onChange={handleChange}
    >
      {activeGeo && loaded && <Marker
        key="me"
        lat={latitude}
        lng={longitude}
        icon={<SC.MarkerIconStyled/>}
        text=""
      />}
      {data.showtime?data.showtime.map((cine, index) => {
        let threate = getThreateInfo(cine.theatersId)
        return <Marker 
            key={index}
            className={ activeCinema === index ? "clickedIcon" : ""}
            lat={threate.lat}
            lng={threate.lng}
            icon={<SC.MarkerCineIconStyled/>}
            text={threate.name}
          />
        }
      ):null}
    </GoogleMapReact>
  </>
  );

  return (
    <SC.Container fullscreen={fullscreen}>
      { isDesktop ?(
        <StickyBox>
          <div className='stickyCont' >
            {content}
          </div>
        </StickyBox>) : content
      }
    </SC.Container>
  );
};

Map.propTypes = {
  data: PropTypes.object,
}

export default Map;