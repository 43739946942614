import React, { useEffect, useState, useRef, useContext } from "react";
import Loader from '../components/Loader';
import { Redirect, useLocation } from "react-router";
import { GeoContext } from '../context/Geotargeting'

const url = process.env.REACT_APP_URL_API;

//params envia como query las props q se seteen
// si agregamos coordinates en param se envia la lat y lon
function withFetch(WrappedComponent, requestUrl, fechOnload = true, loader = true, params = [], enableCache = false) {
  const WithFetch = (props) => {
    const [redirectTo, setRedirect] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const { loaded, latitude, longitude} = useContext(GeoContext);
    const mountRef = useRef(false);
    let query = useLocation().search.replace("?","&");

    useEffect(() => {
      mountRef.current = true;
      if ( mountRef.current && requestUrl && fechOnload){
          fetchData(requestUrl);
      }
      return () =>  mountRef.current = false;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const fetchData = async (requestUrl) => {
      let cachedData = null;
      if(params.length){ 
        params.forEach((param)=>{
          if(query.indexOf(`&${param}=`) === -1){
            if (param === 'coordinates' && loaded){
              query+=`&lat=${latitude}&lon=${longitude}`;
            } else
              if(typeof(props[param]) !== 'undefined')
                query+=`&${param}=${props[param]}`;
          }
        });
        //query+=;
      }
      if(enableCache){
        cachedData = localStorage.getItem(url+requestUrl+query);
        if(cachedData && mountRef.current){
          setData(JSON.parse(cachedData));
          if(mountRef.current)
            setIsLoading(false);
        }
      }
      if(mountRef.current && (cachedData === null))
        setIsLoading(true);
      if(mountRef.current)
        setIsError(false);
      try {
        
        const response = await fetch(url+requestUrl+query, {
          headers: { 'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone },
        });
        if (response.ok) {
          const data = await response.json();
          
          if(data && data.errorType && ( data.errorType === 'RedirectLatam' || data.errorType === 'RedirectBr'))
            window.location = process.env.REACT_APP_URL_TO_REDIRECT;
          if(data && data.errorType && data.errorType === 'MovieNotFound'){
            setRedirect(true)
          }
          if(enableCache && mountRef.current)
            localStorage.setItem(url+requestUrl+query, JSON.stringify(data));
          if(mountRef.current)
            setData(data);
          if(mountRef.current)
            setIsLoading(false);
        } else {
          throw new Error("Fetch request error");
        }
      } catch (err) {
        if(mountRef.current)
          setIsLoading(false);
        if(mountRef.current)
          setIsError(err);
      }
    };
    if(redirectTo)
      return <Redirect to="/404"/>
    
    return (
      (loader && isLoading) ? <Loader /> : <WrappedComponent
        data={data}
        isLoading={isLoading}
        isError={isError}
        {...props}
        getData={(requestUrl) => fetchData(requestUrl)}
      />
    );
  };

  return WithFetch;
}
  
export default withFetch;