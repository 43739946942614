import React, { createContext, useContext, useState } from 'react';

export const GeoContext = createContext({
  loaded: false,
  latitude: null,
  longitude: null,
  city: null,
  country: null,
  state: null,
  zipcode: null,
  countryNotFound: false,
  country_code: null
});

export function GeoProvider(props) {
  const geoContext = useContext(GeoContext);
  const [geo, setGeo] = useState({
    loaded: geoContext.loaded,
    latitude: geoContext.latitude,
    longitude: geoContext.longitude,
  });
  const [geoByIp, setGeoByIp] = useState({
    city: geoContext.city,
    country: geoContext.country,
    state: geoContext.state,
    zipcode: geoContext.zipcode,
    countryNotFound: geoContext.countryNotFound,
    country_code: geoContext.country_code
  });

  const provider = {
    loaded: geo.loaded,
    latitude: geo.latitude,
    longitude: geo.longitude,
    setGeo: setGeo,
    city: geoByIp.city,
    country: geoByIp.country,
    state: geoByIp.state,
    zipcode: geoByIp.zipcode,
    countryNotFound: geoByIp.countryNotFound,
    setGeoByIp: setGeoByIp,
    country_code: geoByIp.country_code
  };

  const { children } = props;

  return <GeoContext.Provider value={provider}>{children}</GeoContext.Provider>;
}

