import React, { useState } from 'react';
import LoaderScreen from '../components/Loader/loaderScreen.js';
import BannerHome from "../components/BannerHome";
import MoviesInTheaters from "../components/MoviesInTheaters";
import NextReleases from "../components/NextReleases";
import Presale from "../components/Presale";
import { ContainerSection, NoMovieErrorCountry } from "../components/MovieCardList/styled";
import { useLanguage } from '../context/Language';
import styled, { css } from "styled-components";

const Btn = styled.a`
  box-sizing: border-box;
  font-family: 'NotoSansSemiBold';
  font-size: 14px;
  color: #FFFFFF;
  background-color: #0070CC;
  border-radius: 5px;
  display: block;
  text-transform: uppercase;
  padding: 14px 30px;
  text-decoration: none;
  margin: 30px auto 0;
  max-width: 275px;
  text-align: center;
  &:hover {
    background-color: #0B3666;
  }
  @media (min-width: 601px){
    max-width: 220px;
    margin-top: 40px;
  }
  @media (min-width: 601px) and (max-width: 960px)  {
    min-width: 103px
  }
  @media (min-width: 961px) {
    margin-top: 30px;
  }
`;

const Container = styled.div`
  ${props=> props.disabled && css`
    display:none;
    overflow: hidden;
    visibility: hidden;
  `}
`;

const ContainerNoMovie = styled.section`
  padding: 46px 25px 55px;
  @media (min-width: 287px) {
    padding: 46px 45px 55px;
  }
  @media (min-width: 601px) {
    padding: 90px 25px 80px;
  }
  @media (min-width: 961px) {
    padding: 135px 25px 177px;
  }
`;


const Home = ({hideLoading, countryNotFound, city}) => {
  let t = useLanguage("home");
  let [loadedBannerHome, setLoadedBannerHome] = useState(false);
  let [loadedInTheaters, setLoadedInTheaters] = useState(false);
  let [loadedPresale, setLoadedPresale] = useState(false);
  let [loadedNextReleases, setLoadedNextReleases] = useState(false);
  let disabled =  !hideLoading || (( hideLoading && countryNotFound && !loadedBannerHome ) || (hideLoading && !countryNotFound && !loadedBannerHome && !loadedInTheaters && !loadedPresale && !loadedNextReleases))
  return (
    <>
    { disabled && <LoaderScreen />}
    <Container disabled={disabled}>
      { hideLoading && <BannerHome onSucess={()=>setLoadedBannerHome(true)} />}
      <ContainerSection>
        {!countryNotFound && hideLoading && <MoviesInTheaters city={city} onSucess={()=>setLoadedInTheaters(true)}/>}
        {!countryNotFound && hideLoading && <Presale city={city}  onSucess={()=>setLoadedPresale(true)}/>}
        {!countryNotFound && hideLoading && <NextReleases onSucess={()=>setLoadedNextReleases(true)} />}
        { countryNotFound && hideLoading && <ContainerNoMovie><NoMovieErrorCountry dangerouslySetInnerHTML={{__html: t('error-CountryNotFound')}}/><Btn href="https://disney.com/">{t("enterHere")}</Btn></ContainerNoMovie>}
      </ContainerSection>
    </Container>
    </>
  );
};


export default Home;
