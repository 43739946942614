import React from "react";
import PropTypes from "prop-types";
import {Link} from 'react-router-dom';
import { trackEvent } from '../../utils/helpers';
import * as SC from "./styled";

const LnkCont = ({children, cta, title, sectionName})=>{
  if(cta.external)
    return <SC.Container className={'movie-soon'} href={cta.href} >{children}</SC.Container>

  let actionValue = sectionName === "en-cartelera" ? "billboard" : "billboard-presale";
  return <SC.Container className={'movie-'+actionValue} as={Link} to={cta.href} onClick={()=>trackEvent('button-click', actionValue, "", title, "", "", "")}>{children}</SC.Container>
}

const MovieCard = ({image, title, cta, sectionName}) => {
  return (
      <LnkCont cta={cta} title={title} sectionName={sectionName}>
        <SC.ContImg><img src={image && image !== '' ? image : 'images/empty-movie-card.jpg'} alt={title} /></SC.ContImg>
        <SC.ContDetails>
          <SC.Title>{title}</SC.Title>
          <SC.BtnCont><span>{cta.text}</span></SC.BtnCont>
        </SC.ContDetails>
      </LnkCont>
  );
};

MovieCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string,
    external: PropTypes.bool
  }).isRequired,
  sectionName: PropTypes.string.isRequired,
};

export default MovieCard;