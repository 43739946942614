import React, { useContext, useEffect } from "react";
import * as SC from "./styled";
import { useLanguage } from '../../context/Language';
import { ReactComponent as ArrowUp } from  "../../assets/images/arrow-up.svg";
import { ReactComponent as ArrowDown } from  "../../assets/images/arrow-down.svg";
import { trackEvent } from '../../utils/helpers';
import { GeoContext } from '../../context/Geotargeting';
import withFetch from "../../hocs/withFetch";

const ShowtimeAccordion = ({
    open, 
    handleClickAccordion, 
    handleClickTime, 
    selectedTime, 
    name, 
    address, 
    avatar, 
    showstime, 
    lat, lng, 
    selectedDate, 
    title, 
    activeGeo, 
    formatFilter,
    data,
    isLoading,
    getData
  }) => {
  let t = useLanguage("showtimeCont");

  const { loaded, latitude, longitude} = useContext(GeoContext);
 
  useEffect(() => {
    if(selectedTime[0] !== -1 && selectedTime[1] !== -1){
      let theater= showstime[selectedTime[0]].schedule[selectedTime[1]].house_id;
      let movie= showstime[selectedTime[0]].schedule[selectedTime[1]].movie_id;
      let showdate= selectedDate;
      let showtime= showstime[selectedTime[0]].schedule[selectedTime[1]].date;
      let comment= showstime[selectedTime[0]].schedule[selectedTime[1]].details;
      getData(`get-url-showtime?theater=${theater}&movie=${movie}&showdate=${showdate}&showtime=${showtime}&comment=${comment}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedTime[0],selectedTime[1]]);

  const handleClickBuy = (e) => {
    if(!isLoading && selectedTime[0] !== -1 && selectedTime[1] !== -1)
      if(showstime[selectedTime[0]]){
        trackEvent('button-click', 'buy', name, title, showstime[selectedTime[0]].format, selectedDate, showstime[selectedTime[0]].schedule[selectedTime[1]].date);
        window.open(data.urlCinema, '_blank');
      }
  }

  const calcDistance = () => {
		var lat1 = graRad(latitude);
		var long1 = graRad(longitude);
		
		var lat2 = graRad(lat);
		var long2 = graRad(lng);

		var d = Math.acos( Math.sin(lat1)*Math.sin(lat2) +
							Math.cos(lat1)*Math.cos(lat2) * 
							Math.cos(long2-long1) ) * 6371;
		return  Math.round(d)+"km";
  }
		
  function graRad(grados){
    var radianes = (grados * Math.PI)/180;
    return radianes;
  }

  return(
  <SC.Container>
    <SC.Header open={open} onClick={handleClickAccordion}>
      <SC.Avatar color={avatar.color}><img src={avatar.image} alt={name} /></SC.Avatar>
        <SC.DetailsCont>
          <SC.Title>{name}</SC.Title>
          <SC.InfoCont><SC.Address>{address}</SC.Address>{activeGeo && loaded && <SC.Distance>{calcDistance()}</SC.Distance>}</SC.InfoCont>
        </SC.DetailsCont>
        <SC.ArrowCont><SC.ArrowText>{t("SeeFunctions")}</SC.ArrowText>{open ? <ArrowUp/> : <ArrowDown />}</SC.ArrowCont>
    </SC.Header>
    <SC.Content className={open ? 'active' : ''} open={open}>
      {
        showstime.map((show, indexShow)=>{
          return((show.format.replace(' ', '-') === formatFilter)||(formatFilter === 'all'))?(<SC.DateTimeCont key={indexShow}>
            <SC.FormatTitle>{show.format}</SC.FormatTitle>
            {show.schedule.map((time, indexTime)=><SC.Time disabled={time.defeated}  key={indexShow+"-"+indexTime} className={selectedTime[0] === indexShow && selectedTime[1] === indexTime  ? 'activeTime' : ''} onClick={()=>!time.defeated && handleClickTime(indexShow, indexTime)}>{time.date}</SC.Time>)}
          </SC.DateTimeCont>):null;
        })
      }
      <SC.Btn disabled={ selectedTime[0] === -1 && selectedTime[1] === -1 } onClick={handleClickBuy}>
        {isLoading && selectedTime[0] !== -1 && selectedTime[1] !== -1 ? <SC.Loader><SC.Dot ms="0" /><SC.Dot ms="160" /><SC.Dot ms="320" /></SC.Loader> : t("goTo") }
        </SC.Btn>
    </SC.Content>
  </SC.Container>
  );
}

export default withFetch(ShowtimeAccordion, "", false, false);