import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import * as SC from "./styled";

const Slider = ({ refCont:{current}, maxWidth, children }) => {
  let [hideLeft, setHideLeft] = useState(true);
  let [hideRight, setHideRight] = useState(true);
  let [position, setPosition,] = useState(0);
  let [clicking, setClicking] = useState(false);

  useEffect(() => {
    if( current && current.firstChild){

      const showHideArrows = () => {
        let srollL = current.scrollLeft === 0;
        let scrollR = current.scrollLeft + current.clientWidth === current.scrollWidth;
        if(srollL !== hideLeft)
          setHideLeft( srollL )
        if(scrollR !== hideRight)
          setHideRight( scrollR)
      };

      const onResize = () => {
        showHideArrows();
      }

      showHideArrows();
      window.addEventListener('resize', onResize);
      current.addEventListener("scroll", showHideArrows);
      return () => {
        window.removeEventListener('resize', onResize);
        current.removeEventListener("scroll", showHideArrows);
      }
    }
  });

  function scrollTo(element, to, duration) {
    var start = element.scrollLeft,
        change = to - start,
        currentTime = 0,
        increment = 20;
        
    var animateScroll = function(){        
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        element.scrollLeft = val;
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }else{
          setClicking(false);
        }
    };
    animateScroll();
  }

  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
  };

  const handleClickLeftArrow = () => {
    if( !clicking ){
      if((position-1) >= 0){
        setClicking(true);
        setPosition(position-1);
        scrollTo(current, current.childNodes[position-1].offsetLeft, 500);
      }
    }
  }
  
  const handleClickRightArrow = () => {
    if( !clicking ){
      if((position+1) < current.childNodes.length){
        setClicking(true);
        setPosition(position+1);
        scrollTo(current, current.childNodes[position+1].offsetLeft, 500);
      }
    }
  }
  
  return (
    <SC.Container maxWidth={maxWidth}>
      <SC.LeftArrow hide={hideLeft} onClick={handleClickLeftArrow}/>
      {children}
      <SC.RightArrow hide={hideRight} onClick={handleClickRightArrow}/>
    </SC.Container>
  );
};

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  refCont: PropTypes.object,
  maxWidth: PropTypes.shape({
    desk: PropTypes.string,
    tablet: PropTypes.string,
    mobile: PropTypes.string
  })
};
Slider.defaultProps = {
  maxWidth:{
    desk: "1090px",//"1120px"
    tablet: "700px",//"720px"
    mobile: '100%' 
  }
}

export default Slider;