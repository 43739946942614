import { createGlobalStyle } from 'styled-components';

import NotoSansRegularTtf from './Noto_Sans/NotoSans-Regular.ttf';
import NotoSansRegularEot from './Noto_Sans/NotoSans-Regular.eot';
import NotoSansRegularOtf from './Noto_Sans/NotoSans-Regular.otf';
import NotoSansRegularWoff from './Noto_Sans/NotoSans-Regular.woff';
import NotoSansRegularWoff2 from './Noto_Sans/NotoSans-Regular.woff2';

import NotoSansItalicTtf from './Noto_Sans/NotoSans-Italic.ttf';
import NotoSansItalicEot from './Noto_Sans/NotoSans-Italic.eot';
import NotoSansItalicOtf from './Noto_Sans/NotoSans-Italic.otf';
import NotoSansItalicWoff from './Noto_Sans/NotoSans-Italic.woff';
import NotoSansItalicWoff2 from './Noto_Sans/NotoSans-Italic.woff2';

import NotoSansBoldItalicTtf from './Noto_Sans/NotoSans-BoldItalic.ttf';
import NotoSansBoldItalicEot from './Noto_Sans/NotoSans-BoldItalic.eot';
import NotoSansBoldItalicOtf from './Noto_Sans/NotoSans-BoldItalic.otf';
import NotoSansBoldItalicWoff from './Noto_Sans/NotoSans-BoldItalic.woff';
import NotoSansBoldItalicWoff2 from './Noto_Sans/NotoSans-BoldItalic.woff2';

import NotoSansBoldTtf from './Noto_Sans/NotoSans-Bold.ttf';
import NotoSansBoldEot from './Noto_Sans/NotoSans-Bold.eot';
import NotoSansBoldOtf from './Noto_Sans/NotoSans-Bold.otf';
import NotoSansBoldWoff from './Noto_Sans/NotoSans-Bold.woff';
import NotoSansBoldWoff2 from './Noto_Sans/NotoSans-Bold.woff2';

import NotoSansExtraBoldTtf from './Noto_Sans/NotoSans-ExtraBold.ttf';
import NotoSansExtraBoldEot from './Noto_Sans/NotoSans-ExtraBold.eot';
import NotoSansExtraBoldOtf from './Noto_Sans/NotoSans-ExtraBold.otf';
import NotoSansExtraBoldWoff from './Noto_Sans/NotoSans-ExtraBold.woff';
import NotoSansExtraBoldWoff2 from './Noto_Sans/NotoSans-ExtraBold.woff2';

import NotoSansSemiBoldTtf from './Noto_Sans/NotoSans-SemiBold.ttf';
import NotoSansSemiBoldEot from './Noto_Sans/NotoSans-SemiBold.eot';
import NotoSansSemiBoldOtf from './Noto_Sans/NotoSans-SemiBold.otf';
import NotoSansSemiBoldWoff from './Noto_Sans/NotoSans-SemiBold.woff';
import NotoSansSemiBoldWoff2 from './Noto_Sans/NotoSans-SemiBold.woff2';

import NotoSansMediumTtf from './Noto_Sans/NotoSans-Medium.ttf';
import NotoSansMediumEot from './Noto_Sans/NotoSans-Medium.eot';
import NotoSansMediumOtf from './Noto_Sans/NotoSans-Medium.otf';
import NotoSansMediumWoff from './Noto_Sans/NotoSans-Medium.woff';
import NotoSansMediumWoff2 from './Noto_Sans/NotoSans-Medium.woff2';

import NotoSansLightTtf from './Noto_Sans/NotoSans-Light.ttf';
import NotoSansLightEot from './Noto_Sans/NotoSans-Light.eot';
import NotoSansLightOtf from './Noto_Sans/NotoSans-Light.otf';
import NotoSansLightWoff from './Noto_Sans/NotoSans-Light.woff';
import NotoSansLightWoff2 from './Noto_Sans/NotoSans-Light.woff2';


export default createGlobalStyle`
    @font-face {
      font-family: 'NotoSansRegular';
      src: url(${NotoSansRegularEot});/* IE9 Compat Modes */
      src: url("${NotoSansRegularEot}?#iefix") format("embedded-opentype"), /* IE6-IE8 */
        url(${NotoSansRegularWoff}) format("woff"), /* Modern Browsers */
        url(${NotoSansRegularOtf}) format("opentype"), /* Open Type Font */
        url(${NotoSansRegularTtf}) format("truetype"), /* Safari, Android, iOS */
        url(${NotoSansRegularWoff2}) format("woff2"); /* Modern Browsers */
      font-style: normal;
      font-weight: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'NotoSansItalic';
      src: url(${NotoSansItalicEot});/* IE9 Compat Modes */
      src: url("${NotoSansItalicEot}?#iefix") format("embedded-opentype"), /* IE6-IE8 */
        url(${NotoSansItalicWoff}) format("woff"), /* Modern Browsers */
        url(${NotoSansItalicOtf}) format("opentype"), /* Open Type Font */
        url(${NotoSansItalicTtf}) format("truetype"), /* Safari, Android, iOS */
        url(${NotoSansItalicWoff2}) format("woff2"); /* Modern Browsers */
      font-style: normal;
      font-weight: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'NotoSansBoldItalic';
      src: url(${NotoSansBoldItalicEot});/* IE9 Compat Modes */
      src: url("${NotoSansBoldItalicEot}?#iefix") format("embedded-opentype"), /* IE6-IE8 */
        url(${NotoSansBoldItalicWoff}) format("woff"), /* Modern Browsers */
        url(${NotoSansBoldItalicOtf}) format("opentype"), /* Open Type Font */
        url(${NotoSansBoldItalicTtf}) format("truetype"), /* Safari, Android, iOS */
        url(${NotoSansBoldItalicWoff2}) format("woff2"); /* Modern Browsers */
      font-style: normal;
      font-weight: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'NotoSansBold';
      src: url(${NotoSansBoldEot}); /* IE9 Compat Modes */
      src: url("${NotoSansBoldEot}?#iefix") format("embedded-opentype"), /* IE6-IE8 */
        url(${NotoSansBoldWoff}) format("woff"), /* Modern Browsers */
        url(${NotoSansBoldOtf}) format("opentype"), /* Open Type Font */
        url(${NotoSansBoldTtf}) format("truetype"), /* Safari, Android, iOS */
        url(${NotoSansBoldWoff2}) format("woff2"); /* Modern Browsers */
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'NotoSansSemiBold';
      src: url(${NotoSansSemiBoldEot}); /* IE9 Compat Modes */
      src: url("${NotoSansSemiBoldEot}?#iefix") format("embedded-opentype"), /* IE6-IE8 */
        url(${NotoSansSemiBoldWoff}) format("woff"), /* Modern Browsers */
        url(${NotoSansSemiBoldOtf}) format("opentype"), /* Open Type Font */
        url(${NotoSansSemiBoldTtf}) format("truetype"), /* Safari, Android, iOS */
        url(${NotoSansSemiBoldWoff2}) format("woff2"); /* Modern Browsers */
      font-style: normal;
      font-weight: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'NotoSansExtraBold';
      src: url(${NotoSansExtraBoldEot}); /* IE9 Compat Modes */
      src: url("${NotoSansExtraBoldEot}?#iefix") format("embedded-opentype"), /* IE6-IE8 */
        url(${NotoSansExtraBoldWoff}) format("woff"), /* Modern Browsers */
        url(${NotoSansExtraBoldOtf}) format("opentype"), /* Open Type Font */
        url(${NotoSansExtraBoldTtf}) format("truetype"), /* Safari, Android, iOS */
        url(${NotoSansExtraBoldWoff2}) format("woff2"); /* Modern Browsers */
      font-style: normal;
      font-weight: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'NotoSansMedium';
      src: url(${NotoSansMediumEot}); /* IE9 Compat Modes */
      src: url("${NotoSansMediumEot}?#iefix") format("embedded-opentype"), /* IE6-IE8 */
        url(${NotoSansMediumWoff}) format("woff"), /* Modern Browsers */
        url(${NotoSansMediumOtf}) format("opentype"), /* Open Type Font */
        url(${NotoSansMediumTtf}) format("truetype"), /* Safari, Android, iOS */
        url(${NotoSansMediumWoff2}) format("woff2"); /* Modern Browsers */
      font-style: normal;
      font-weight: normal;
      font-display: swap;
    }
    @font-face {
      font-family: 'NotoSansLight';
      src: url(${NotoSansLightEot}); /* IE9 Compat Modes */
      src: url("${NotoSansLightEot}?#iefix") format("embedded-opentype"), /* IE6-IE8 */
        url(${NotoSansLightWoff}) format("woff"), /* Modern Browsers */
        url(${NotoSansLightOtf}) format("opentype"), /* Open Type Font */
        url(${NotoSansLightTtf}) format("truetype"), /* Safari, Android, iOS */
        url(${NotoSansLightWoff2}) format("woff2"); /* Modern Browsers */
      font-style: normal;
      font-weight: normal;
      font-display: swap;
    }
`;