import styled, { keyframes,css } from "styled-components";

export const Container = styled.li`
  list-style: none;
  margin: 0px;
  padding: 0px;
  border-top: 3px solid #FFF;
  border-bottom: 3px solid #DFDDDC;
  border-right: 1px solid #DFDDDC;
  &:first-child{
    border-top: none;
  }
`;

export const Header = styled.div`
  display:flex;
  flex-shrink: 0;
  align-items: center;
  margin: 0px;
  padding: 32px 40px;
  background-color: ${props => props.open ? '#FFFFFF' : '#F1F2F3'};
  &:hover{
    cursor: pointer;
    background-color: #DFDDDC;
  }
  @media (max-width: 320px){
    padding: 32px 20px;
  }
`;


export const Avatar = styled.div`
  flex-shrink: 0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: ${props => props.color ? props.color : '#0070CC'};
  position: relative;
  & img{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

export const DetailsCont = styled.div`
  padding: 0 10px;
  width: 100%;
  overflow: hidden;
`;

export const Title = styled.h3`
  margin: 0 0 5px;
  padding: 0;
  font-family: "NotoSansSemiBold";
  font-size: 18px;
  line-height: 22px;
  color: #1D1E1F;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (min-width: 601px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const InfoCont = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Address = styled.div`
  font-family: "NotoSansLight";
  font-size: 14px;
  color: #000;
  padding-right: 10px;
  display: inline;
  @media (min-width: 601px) {
    font-size: 16px;
  }
`;

export const Distance = styled.div`
  font-family: "NotoSansBold";
  font-size: 12px;
  line-height: 12px;
  color: #000;
  padding-left: 10px;
  border-left: 2px solid #000;
  display: inline;
  @media (min-width: 601px) {
    font-size: 14px;
    line-height: 14px;
  }
`;


export const ArrowCont = styled.div`
  flex-shrink:0;
  & rect {
    fill: #0070CC;
  }
`;

export const ArrowText = styled.div`
  display: none;
  @media (min-width: 601px) and (max-width: 960px), (min-width: 1170px) {
    display: inline-block;
    font-family: "NotoSansExtraBold";
    font-size: 16px;
    color: #0077DA;
    text-transform: uppercase;
    padding-right: 10px;
  }
`;


export const Content = styled.div`
  display: block;
  margin: 0px;
  padding: ${props => props.open ? '0px 40px 52px' : '0 40px'};
  background-color: #FFFFFF;
  max-height: 0;
  overflow: hidden;
  transition: all .5s;
  &.active {
    max-height: 1000vh;
    overflow: visible;
    transition: all .5s;
  }
  @media (max-width: 320px){
    padding: ${props => props.open ? '0px 20px 15px' : '0 20px'};
  }
`;

export const DateTimeCont = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0px 0 20px;
  @media (min-width: 601px) {
    font-family: "NotoSansSemiBold";
    padding: 0px 0 28px;
  }
  &:before{
    content: "";
    border-top: 1px solid #DCDDDF;
    display: block;
    flex: 0 0 calc(100% + 80px);
    height: 20px;
    margin: 0 -40px;
    @media (min-width: 601px){
      height: 32px;
    }
    @media (max-width: 320px){
      flex: 0 0 calc(100% + 40px);
      margin: 0 -20px;
    }
  }
  &:first-child:before {
    border-top: none;
    height: 0;
    content: none;
  }
`;

export const FormatTitle = styled.h3`
  width: 100%;
  padding: 0px;
  margin: 0px;
  font-family: "NotoSansMedium";
  font-size: 14px;
  text-transform: uppercase;
  @media (min-width: 601px) {
    font-family: "NotoSansSemiBold";
    font-size: 16px;
  }
`;

export const Time = styled.div`
  font-family: "NotoSansMedium";
  font-size: 14px;
  padding: 14px 17px;
  margin-right: 5px;
  margin-top: 20px;
  color: ${props=>props.disabled ? '#FFF' : '#0077DA'};
  background-color:  ${props => props.disabled ? '#DFDDDC' : 'transparent'};
  
  border-radius: 4px;
  ${props=> !props.disabled && css`
    border: 1px solid #0077DA;
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 119, 218, .8);
      color: #FFF;
    }
    &.activeTime {
      color: #FFF;
      background-color: #0077DA;
    }
  `}
  @media (min-width: 601px) {
    font-size: 16px;
    margin-right: 25px;
    margin-top: 22px;
    padding: 12px 24px;
  }

  @media (min-width: 961px){
    padding: 12px 21px;
    margin-right: 20px;
    margin-top: 18px;
  }
`;


export const Btn = styled.div`
box-sizing: border-box;
  border-radius: 5px;
  background-color:  ${props => props.disabled ? '#DFDDDC' : '#0077DA'};
  font-family: "NotoSansBold";
  font-size: 16px;
  text-align: center;
  color: #FFF;
  padding: 13px;
  margin-top: 23px;
  &:hover {
    cursor: pointer;
    background-image:  ${props => props.disabled ? 'none' : 'linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3))'};
  }
  @media (min-width: 601px) {
    max-width: 358px;
    margin-left: auto;
    margin-right: auto;
  }
`;


export const Loader = styled.div`
  color: #FFF;
  display: inline-flex;
  padding: 0px;
  transition: color 150ms;
  align-self: center;
  font-size: 1em;
  line-height: 1em;
  margin-right: 4px;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  justify-content: center;
`;

const loading = keyframes`
  0%, 80%, 100%{
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
`;

export const Dot = styled.div`
  color: #FFF;
  animation: ${loading} 1s ease-in-out infinite;
  animation-delay: ${props => props.ms+'ms'};
  background-color: #FFF;
  border-radius: 1em;
  display: inline-block;
  height: 1em;
  vertical-align: top;
  width: 1em;
  line-height: 22px;
  margin: 0 5px;
`;


