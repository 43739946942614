import styled from "styled-components";
import Select from 'react-select-virtualized';
import { Scrollbar } from "react-scrollbars-custom";
import locationIcon from  "../../assets/images/location-btn.png";

export const Container = styled.section`
  background-color: #F1F2F3;
  width: 100%;
  box-sizing: border-box;
  order: 2;
  @media (min-width: 961px) {
    flex: 0 0 41.1875%;
    padding: 0px;
    order: 1;
  }
  & .react-select-virtualized__control {
    background-color: rgb(255, 255, 255,.15);
    border: 1px solid #FFF;
    & .react-select-virtualized__placeholder{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(100% - 9px);
    }
    & .react-select-virtualized__value-container {
      padding: 9px 0px 9px 9px;
    }
    & .react-select-virtualized__indicators{
      & .react-select-virtualized__indicator-separator{
        display: none;
      }
      & .react-select-virtualized__dropdown-indicator {
        color: #FFF;
      }
    }
  }
  & .react-select-virtualized__menu,
  & .react-select-virtualized__value-container, 
  & .react-select-virtualized__placeholder,
  & .react-select-virtualized__single-value,
  & .react-select-virtualized__input{
    font-size: 16px;
    line-height: 22px;
    font-family: "NotoSansMedium";
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  & .react-select-virtualized__value-container, 
  & .react-select-virtualized__placeholder,
  & .react-select-virtualized__single-value,
  & .react-select-virtualized__input,
  & .react-select-virtualized__dropdown-indicator,
  & .react-select-virtualized__loading-indicator{
    color: #FFF;
  }
`;

export const Filters = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 25px 40px 15px;
  background: transparent linear-gradient(181deg, #0077DA 0%, #183663 100%) 0% 0% no-repeat;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 320px) {
    padding: 25px 20px;
  }
  @media (min-width: 601px) {
    padding: 35px 40px 31px;
  }
  @media (min-width: 961px) {
    padding: 25px 25px 13px;
  }
  @media (min-width: 1137px) {
    padding: 25px 40px 13px;
  }

`;

export const Title = styled.div`
  color: #FFF;
  font-family: "NotoSansMedium";
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  flex: 0 0 100%;
  @media (min-width: 601px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (min-width: 961px) {
    font-size: 17px;
    line-height: 23px;
  }
`;

export const Country = styled(Select)`
  flex: 0 0 50%;
  margin-top: 15px;
  padding-right: 7px;
  @media (min-width: 601px) {
    margin-top: 25px;
  }
`;
export const State = styled(Select)`
  flex: ${props => props.isLatam ?  '0 0 50%' : '0 0 100%'};
  margin-top: 15px;
  padding-left: ${props => props.isLatam ?  '7px' : '0px'};
  @media (min-width: 601px) {
    margin-top: 25px;
  }
`;
export const District = styled(Select)`
  flex: 0 0 100%;
  margin-top: 15px;
  @media (min-width: 601px) {
    flex: 0 0 50%;
    padding-right: 7px;
  }
`;

export const MovieFormat = styled(Select)`
  flex: 0 0 100%;
  margin-top: 15px;
  @media (min-width: 601px) {
    flex: 0 0 50%;
    padding-left: 7px;
  }
`;

export const Calendar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 25px;
  padding: 0 10px 0  ;
  @media (max-width: 320px) {
    padding: 10px 0px 0px;
  }
  @media (min-width: 601px) {
    margin-top: 58px;
  }
  @media (min-width: 961px) {
    margin-top: 28px;
  }
`;

export const DayCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Month = styled.span`
  color: #DCDDDF;
  font-family: "NotoSansRegular";
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  @media (min-width: 601px) {
    font-size: 14px;
    line-height: 19px;
  }
  @media (min-width: 961px) {
    font-size: 13px;
    line-height: 18px;
  }
`;

export const Day = styled.span`
  color: #FFF;
  font-family: "NotoSansRegular";
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  @media (min-width: 601px) {
    font-size: 14px;
    line-height: 19px;
  }
  @media (min-width: 961px) {
    font-size: 13px;
    line-height: 18px;
  }
`;

export const Number = styled.div`
  -webkit-tap-highlight-color: transparent;
  color: #FFF;
  font-family: "NotoSansRegular";
  font-size: 18px;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  margin-top: 10px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 50%;
  display: inline-block;
  &:hover{
    border-color: #0070CC;
    background-color: rgba(0, 112, 204,.5);
  }
  &.active{
    font-family: "NotoSansBold";
    background-color: #0070CC;
    cursor: initial;
    &:hover{
      border-color: transparent;
      background-color: #0070CC;
    }
  }
  @media (min-width: 601px) {
    &.active{
      font-family: "NotoSansExtraBold";
      font-size: 22px;
    }
    font-size: 24px;
    width: 43px;
    height: 43px;
    line-height: 43px;
  }
`;

export const ScrollbarStyled = styled(Scrollbar)`
  scroll-behavior: smooth;
  & .ScrollbarsCustom-Content{
    display: block !important;
  }

  @media (min-width: 961px) {
    height: ${props => props.filtersheight ? `calc(100% - ${props.filtersheight}px) !important` : '100%'};
    & .ScrollbarsCustom-Track{
      background: #FFFFFF !important;
      & .ScrollbarsCustom-Thumb {
        background: #66A9E0 !important;
      }
    }
    
  }
`;

export const AccordionCont = styled.ul`
  margin: 0px;
  padding: 0px;
`;

export const MovieTitle = styled.h2`
  display: none;
  @media (min-width: 961px){
    display: block;
    font-family: "NotoSansBold";
    font-size: 24px;
    line-height: 30px;
    color: #0070CC;
    text-align: center;
    margin: 0px;
    padding: 18px;
    background-color: #F1F2F3;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 8px -4px inset;
  }
`;

export const NoData = styled.p`
  font-family: "NotoSansMedium";
  font-size: 16px;
  color: #6C6D6F;
  text-transform: uppercase;
  text-align: center;
  margin: 40px 33px;
  @media (min-width: 961px){
    font-size: 18px;
    max-width: 430px;
    margin: 64px auto;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const BtnLocation = styled.div`
  margin: 28px auto 0;
  font-family: 'NotoSansSemiBold';
  text-align: center;
  font-size: 12px;
  color: #FFFFFF;
  background-color: #0070CC;
  border-radius: 5px;
  border: 2px solid transparent;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  padding: 14px 30px;
  transition: transform 0.1s, -webkit-transform 0.1s;
  transform: scale(0.98, 0.98);
  box-sizing: border-box;
  &:hover {
    background-image: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3));
    border: 2px solid #0070CC;
    background-image: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3));
    transform: translateZ(0) scale(1, 1);
  }
  &::before {
    content: "";
    display: inline-block;
    background: url(${locationIcon}) no-repeat center;
    width: 13px;
    height: 18px;
    vertical-align: middle;
    margin-right: 10px;
  }
`;