import styled from "styled-components";
import { Container as MovieCard } from "../MovieCard/styled";

export const ContainerSection = styled.div`
  background: transparent linear-gradient(181deg, #0077DA 0%, #0D3C73 100%) 0% 0% no-repeat;
  padding-bottom: 65px;
  @media (min-width: 601px) {
    padding-bottom: 70px;
  }
  @media (min-width: 961px) {
    padding-bottom: 100px;
  }
`;

export const Container = styled.section`
  padding: 60px 25px 0px;

  @media (min-width: 287px) {
    padding: 50px 45px 0px;
  }
  @media (min-width: 601px) {
    padding: 50px 25px 0px;
  }
  @media (min-width: 961px) {
    padding: 60px 25px 0px;
  }
`;

export const Title = styled.h2`
  font-family: "NotoSansRegular";
  font-size: 24px;
  text-transform: uppercase;
  color: #FFF;
  text-align: left;
  font-weight: normal;
  margin: 0 0 30px;
  @media (min-width: 601px) {
    text-align: center;
    margin: 0 0 30px;
  }
  @media (min-width: 961px) {
    margin: 0 0 60px;
    font-size: 30px;
  }
`;

export const MovieCardCont = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  scrollbar-width: none;

  width: 100%;
  justify-content: left;
  &::-webkit-scrollbar {
    display: none;
  }
  & ${MovieCard}:first-child{
    margin-left: 0;
  }
  
  @media (min-width: 601px) {
    padding-left: 0px;
    max-width: 700px;
    margin: 0 auto;
    & ${MovieCard}:first-child {
      margin-left: auto;
    }
    & ${MovieCard}:last-child {
      margin-right:auto;
    }
  }
  @media (min-width: 961px) {
    max-width: 1105px;
  }
`;

export const NoMovie = styled.div`
  color: #FFF;
  font-family: "NotoSansRegular";
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  background-color: rgba(4,60,113,.3);
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  @media (min-width: 601px) {
    text-align: center;
    padding: 25px;
    margin: 0 auto;
    max-width: 639px;
    box-sizing: border-box;
  }
  @media (min-width: 961px) {
    max-width: 967px;
    box-sizing: border-box;
    font-size: 24px;
    line-height: 32px;
  }
`;

export const NoMovieErrorCountry = styled(NoMovie)`
  margin: 0px;
  font-size: 18px;
  line-height: 28px;
  background: none;
  padding: 0;
  text-align: left;
  & span {
    font-family: "NotoSansBold";
    text-transform: uppercase;
  }

  @media (min-width: 601px) {
    margin: 0 auto;
    font-size: 20px;
    line-height: 34px;
    text-align: center;
  }
  @media (min-width: 961px) {
    font-size: 24px;
    line-height: 40px;
  }
`;