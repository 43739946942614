import styled from "styled-components";

export const ViewMore = styled.div`
  display: none;
  margin-top: 20px;
  & span {
    font-family: 'NotoSansSemiBold';
    text-align: center;
    font-size: 14px;
    color: #FFFFFF;
    background-color: #0070CC;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    padding: 14px 30px;
    transition: transform 0.1s, -webkit-transform 0.1s;
    transform: scale(0.98, 0.98);
    box-sizing: border-box;
  }
  @media (min-width: 601px) {
    display: block;
    & span {
      max-width: 137px;
      width: 100%;
    }
  }
  @media (min-width: 601px) and (max-width: 740px) {
    margin-top: 5px;
  }
  @media (min-width: 961px) {
    & span {
      max-width: 193px;
      width: 100%;
    }
  }

`;

export const Container = styled.section`
  margin: 0px;
  position: relative;
  padding-bottom: 53.06%;
  height: 0px;
  @media (min-width: 601px) and (max-width: 960px)  {
      padding-bottom: 35.8%;
    }
    @media (min-width: 961px) {
      padding-bottom: 27.31%;
    }
  &:hover img{
    transform: scale(1.03);
  }
  &:hover ${ViewMore} span{
    background-image: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3));
    transform: translateZ(0) scale(1, 1);
  }
  & a {
    display:block;
  }
`;

export const ContainerLoading = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const ContImg = styled.div`
  overflow: hidden;
  box-shadow: 0px 6px 8px rgba(0,0,0, .16);
  & img{
    display: block;
    width: 100%;
    max-width: 100%;
    transform: scale(1);
    transition: transform 400ms;
    
    &.banner-home-desk,
    &.banner-home-tablet{
      display: none;
    }
  }
  @media (min-width: 961px) {
    & img.banner-home-desk{
      display: block;
    }
    & img.banner-home-mobile,
    & img.banner-home-tablet{
      display: none;
    }
  }
  @media (min-width: 601px) and (max-width: 960px)  {
    & img.banner-home-desk,
    & img.banner-home-mobile{
      display: none;
    }
    & img.banner-home-tablet{
      display: block;
    }
  }
`;

export const Content = styled.div`  
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #FFFFFF;
  padding: 0;
  box-sizing: border-box;
  & .display-inline-block {
    display: inline-block;
  }
  @media (min-width: 601px) {
    width: 37%;
    justify-content: center;
    padding: 0 5%;
  }
  @media (min-width: 961px) {
    width: 39%;
  }
`;

export const Title = styled.div`
  display: none;
  margin-bottom: 20px;
  font-family: 'NotoSansSemiBold';
  @media (min-width: 601px) and (max-width: 685px) {
    display: block;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 5px;
  }
  @media (min-width: 686px) and (max-width: 740px) {
    display: block;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 5px;
  }
  @media (min-width: 741px) and (max-width: 960px) {
    display: block;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 10px
  }
  @media (min-width: 961px) and (max-width: 1315px)  {
    display: block;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 42px;
  }
  @media (min-width: 1316px) {
    display: block;
    font-size: 34px;
    line-height: 46px;
  }
`;

export const Desc = styled.div`
  font-size: 12px;
  font-family: 'NotoSansMedium';
  line-height: 18px;
  &.description-mobile {
    display: block;
  }
  &.description-desktop,
  &.description-tablet {
    display: none;
  }
  @media (max-width: 600px) {
    text-align: left;
    background-color: #0B3666;
    width: 100%;
    max-width: 90%; 
    font-family: 'NotoSansMedium';
    margin-bottom: 18px;
    box-sizing: border-box;
    padding: 10px 20px 10px 25px;
    
  }

  @media (min-width: 287px) and (max-width: 600px) {
    padding: 10px 20px 10px 45px;
  }

  @media (min-width: 601px) {
    font-family: 'NotoSansRegular';
    &.description-tablet {
      display: block;
    }
    &.description-desktop,
    &.description-mobile {
      display: none;
    }
  }

  @media (min-width: 376px) and (max-width: 600px) {
    font-size: 14px;
    line-height: 22px;
  }

  @media (min-width: 601px) and (max-width: 685px) {
    font-size: 13px;
    line-height: 22px;
  }

  @media (min-width: 686px) and (max-width: 740px) {
    font-size: 14px;
    line-height: 22px;
  }

  @media (min-width: 741px) and (max-width: 960px) {
    font-size: 16px;
    font-family: 'NotoSansRegular';
    line-height: 24px;
  }

  @media (min-width: 961px){
    &.description-desktop {
      display: block;
    }
    &.description-tablet,
    &.description-mobile {
      display: none;
    }
  }

  @media (min-width: 961px) and (max-width: 1315px)  {
    display: block;
    line-height: 27px;
    font-size: 18px;
    font-family: 'NotoSansRegular';
  }
  @media (min-width: 1316px) {
    font-size: 24px;
    font-family: 'NotoSansRegular';
    line-height: 40px;
  }
  & b{
    font-family: 'NotoSansBold';
  }
`;

