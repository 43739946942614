import React from 'react';
import PropTypes from 'prop-types'
import styled, { keyframes } from "styled-components";
import { useLanguage } from '../../context/Language';
import { ContainerSection } from "../MovieCardList/styled";
import loadingBlue from  "../../assets/images/loading-blue.png";
import loadingWhite from  "../../assets/images/loading-white.png";

const spin = keyframes`
  0%   {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;

const ContStyled = styled(ContainerSection)`
  padding: 0px;
`;

const SpinCont = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  min-height: calc(100vh - 500px);
  & img {
    animation: ${spin} 2s linear infinite;
    margin: 0 auto;
    max-width: 100%;
    width: ${props => `${props.xs}px`};
    display: block;
    @media (min-width: 601px) {
      width: ${props => `${props.xm}px`};
    }
    @media (min-width: 961px) {
      width: ${props => `${props.xd}px`};
    }

  }
`; 
const TextStyled = styled.p`
  text-align: center;
  color: ${props => props.color};
  margin: 0;
  font-family: "NotoSansMedium";
  font-size: ${props => `${props.textSize.mobile}px`};
  @media (min-width: 601px) {
    font-size: ${props => `${props.textSize.tablet}px`};
  }
  @media (min-width: 961px) {
    font-size: ${props => `${props.textSize.desk}px`};
  }
`; 

const LoaderScreen = ({background, white, widthImage, textSize}) => {
  let t = useLanguage("loader");
  if(background)
    return(
      <ContStyled>
        <SpinCont xs={widthImage.mobile} xm={widthImage.tablet} xd={widthImage.desk}>
          <img src={loadingWhite} alt="Cargando..." />
          <TextStyled color="#FFF" textSize={textSize}>{t("text")}</TextStyled>
        </SpinCont>
      </ContStyled>
    );
  return(
    <SpinCont xs={widthImage.mobile} xm={widthImage.tablet} xd={widthImage.desk}>
      <img src={white ? loadingWhite : loadingBlue} alt="Cargando..." />
      <TextStyled color={white ? "#FFF" : "#0070CC"} textSize={textSize}>{t("text")}</TextStyled>
    </SpinCont>
  )
}

LoaderScreen.propTypes = {
  background: PropTypes.bool,
  white: PropTypes.bool,
  widthImage: PropTypes.shape({
    desk: PropTypes.number,
    tablet: PropTypes.number,
    mobile: PropTypes.number
  }),
  textSize: PropTypes.shape({
    desk: PropTypes.number,
    tablet: PropTypes.number,
    mobile: PropTypes.number
  }),
}

LoaderScreen.defaultProps = {
  background: true,
  white: false,
  widthImage: {
    desk: 150,
    tablet: 150,
    mobile: 100
  },
  textSize: {
    desk: 20,
    tablet: 20,
    mobile: 18
  },
}

export default LoaderScreen;

