import styled from "styled-components";
import logo from  "../../assets/images/logo-disney-footer.svg"

export const Container = styled.footer`
  text-align: center;
  margin: 50px 20px;
  @media screen and (min-width: 960px) {
    margin: 50px;
  }
`;

export const NavContainer = styled.div`
  text-align: center;
`;

export const LogoDisney = styled.div`
  display: block;
  margin: 0 auto 30px;
  min-width: 122px;
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;
  height: 57px;
  width: 131px;
`;

export const Nav = styled.nav`
  text-align: center;
`;

export const Ul = styled.ul`
  color: #1d1e1f;
  margin: 0;
  padding: 0;
`;

export const Li = styled.li`
  margin: 20px 0;
  padding: 0;
  display: block;
  @media screen and (min-width: 680px) {
    display: inline-block;
    margin: 0 0 20px 0;
  }

  @media screen and (min-width: 680px) {
    margin-right: 27px;
  }

  &:last-child{
    margin-right: 0;
  }
`;

export const Lnk = styled.a`
  color: #0A1016;
  font-family: 'NotoSansBold';
  font-size: 14px;
  text-decoration: none;
  letter-spacing: 0.01em;
`;

export const UlTyC = styled.ul`
  padding: 16px 0 0;
  margin: 0;
  text-align: center;
`;

export const LiTyC = styled.li`
  padding: 0;
  display: inline-block;
  line-height: 17px;
  &:after {
    content: "\\2022";
    display: inline-block;
    line-height: 17px;
    vertical-align: middle;
    color: #464444;
    padding: 0 5px;
    font-size: 12px;
    font-family: 'NotoSansRegular';
  }

  &:last-child:after{
    content: '';
    display: none;
  }
  
`;

export const LnkTyC = styled.a`
  color: #464444;
  font-family: 'NotoSansRegular';
  font-size: 12px;
  text-decoration: none;
`;

export const Copyright = styled.p`
  padding: 0;
  margin: 0;
  color: #464444;
  font-family: 'NotoSansRegular';
  font-size: 12px;
`;
