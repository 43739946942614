import React from 'react';
import styled from "styled-components";
import {Link} from 'react-router-dom';
import { useLanguage } from '../context/Language';
import lilo from "../assets/images/lilo-min.png";

const Container = styled.div`
  background: transparent linear-gradient(181deg, #0077DA 0%, #0D3C73 100%) 0% 0% no-repeat;
  color: #FFF;
  text-align: center;
  padding: 73px 45px 128px;
`;

const ErrorTxt = styled.div`
  font-family: "NotoSansRegular";
  font-size: 25px;
  line-height: 35px;
  @media (min-width: 601px){
    font-size: 30px;
    line-height: 41px;
  }
`;

const ErrorCodeTxt = styled.div`
  font-family: "NotoSansBold";
  font-size: 100px;
  line-height: 100px;
  margin-bottom: 40px;
  @media (min-width: 601px){
    font-size: 200px;
    line-height: 200px;
  }
`;

const TitleTxt = styled.p`
  font-family: "NotoSansMedium";
  font-size: 20px;
  line-height: 25px;
  margin: 0px;
  @media (min-width: 601px){
    font-size: 24px;
    line-height: 30px;
  }
`;

const DescTxt = styled.p`
  font-family: "NotoSansRegular";
  font-size: 16px;
  line-height: 25px;
  margin: 0px;
  @media (min-width: 601px){
    font-size: 18px;
    line-height: 30px;
  }
`;

const Btn = styled(Link)`
  box-sizing: border-box;
  font-family: 'NotoSansSemiBold';
  font-size: 14px;
  color: #FFFFFF;
  background-color: #0070CC;
  border-radius: 5px;
  display: block;
  text-transform: uppercase;
  padding: 14px 30px;
  text-decoration: none;
  margin: 40px auto 0;
  max-width: 275px;
  text-align: center;
  &:hover {
    background-color: #0B3666;
  }
  @media (min-width: 601px){
    max-width: 220px;
  }
  @media (min-width: 601px) and (max-width: 960px)  {
    min-width: 103px
  }

`;

const NotFound = () => {
  let t = useLanguage("notFound");
  return (
    <Container>
      <ErrorTxt>{t("error")}</ErrorTxt>
      <ErrorCodeTxt>404</ErrorCodeTxt>
      <img src={lilo} alt="Error 404" />
      <TitleTxt>{t("title")}</TitleTxt>
      <DescTxt dangerouslySetInnerHTML={{__html: t("desc")}} />
      <Btn to="/">{t("goTo")}</Btn>
    </Container>
  );
};


export default NotFound;