import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";
import { Container, ContImg } from '../MovieCard/styled';
import MovieCardList from "../MovieCardList";
import { useLanguage } from '../../context/Language';
import withFetch from "../../hocs/withFetch";

const MovieCardListStyled = styled(MovieCardList)`
  ${Container} {
    position: relative;
  }
  /*${ContImg}:before{
    content: ${props => `"${props.title}"`};
    color: #FFF;
    font-size: 12px;
    line-height: 17px;
    font-family: 'NotoSansSemiBold';
    background-color: #0077DA;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    top: 22px;
    z-index: 99;
    padding: 6px 12px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    @media (min-width: 601px)  {
      font-size: 10px;
      padding: 2px 10px;
    }
    @media (min-width: 961px)  {
      font-size: 14px;
      padding: 8px 16px;
    }
  }*/
`;

const Presale = ({onSucess, data, isLoading }) => {
  useEffect(() => {
    if(!isLoading)
      onSucess()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  let t = useLanguage("home");
  
  if(!isLoading && data.errorType && data.errorType === "CountryNotFound")
    return <></>
  else
    return <MovieCardListStyled title={t('preventa').title} sectionName="preventa" isLoading={isLoading} data={data} external={false} />
};

Presale.propTypes = {
  onSucess: PropTypes.func,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
        href: PropTypes.string,
      })
    ),
    PropTypes.objectOf(PropTypes.string)
  ]),
  isLoading: PropTypes.bool
};
/*
Presale.defaultProps = {
data: [
  {
    image: "/images/empty-movie-card.jpg",
    title: "THE KING’S MAN",
    slug: "kings-man",
  },
  {
    image: "/images/free-guy-movie-card.jpg",
    title: "FREE GUY",
    slug: "free-guy",
  }
],
isLoading:false
}
export default Presale;
*/
export default withFetch(Presale, `presale?region=${process.env.REACT_APP_REGION}`, true, false, ['city'], true);