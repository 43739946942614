import React, { useState, useEffect, useContext } from 'react';
import * as SC from "./styled";
import { GeoContext } from '../../context/Geotargeting';
import { useLanguage } from '../../context/Language';
import { ReactComponent as CloseIcon }  from '../../assets/images/close.svg';

const Snackbar = () => {
  const [open, setOpen] = useState(false);
  const [activeTransition, setActiveTransition] = useState(false);
  let t = useLanguage("snackbar");
  const {setGeo} = useContext(GeoContext);

  useEffect(() => {
    if ("geolocation" in navigator) {

      setTimeout(() => {
        setOpen(true);
        setTimeout(() => {
          setActiveTransition(true);
        }, 500);
      }, 500);

      function success(pos) {
        var crd = pos.coords;
        /*
        console.log('Your current position is:');
        console.log('Latitude : ' + crd.latitude);
        console.log('Longitude: ' + crd.longitude);
        */
        setGeo({ loaded: true, latitude: crd.latitude, longitude: crd.longitude})
        
        closeSnackbar()
      };
      
      function error(err) {
        if( err.code === 1)
          closeSnackbar()
      };
      navigator.geolocation.getCurrentPosition(success, error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const closeSnackbar = () => {
    setActiveTransition(false);
    setTimeout(() => {
      setOpen(false);
    }, 500);
  }

  if (open)
    return (
      <SC.Container className={activeTransition && 'active'}>
        <SC.SnackCont>
          <SC.LocIcon />  
          <SC.Text>{t('text')}</SC.Text>
          <SC.Button onClick={closeSnackbar}><CloseIcon /></SC.Button>
        </SC.SnackCont>
      </SC.Container>
    )
  else return <></>
}

export default Snackbar;