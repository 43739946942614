import React, { useEffect, useState, useRef } from "react";
import ShowtimeAccordion from "../ShowtimeAccordion";
import { useLanguage } from "../../context/Language";
import withFetch from "../../hocs/withFetch";
import * as SC from "./styled";
import moment from "moment";
import Loader from "../Loader/loaderScreen";

if (process.env.REACT_APP_REGION === "br") require("moment/locale/pt-br");
else require("moment/locale/es-mx");

const CountrySelect = withFetch(
  ({ data, isLoading, value, placeholder, handleChange }) => {
    return (
      <SC.Country
        value={
          isLoading ? null : data.filter((country) => country.value === value)
        }
        classNamePrefix="react-select-virtualized"
        isLoading={isLoading}
        options={data}
        placeholder={placeholder}
        isClearable={false}
        onChange={(value) => handleChange(value)}
      />
    );
  },
  `countries?region=${process.env.REACT_APP_REGION}`,
  true,
  false
);

const StateSelect = withFetch(
  ({
    country,
    data,
    isLoading,
    getData,
    isLatam,
    value,
    dafaultValue,
    placeholder,
    handleChange,
  }) => {
    useEffect(() => {
      if (country) getData(`states?region=${process.env.REACT_APP_REGION}`);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country]);

    useEffect(() => {
      if (!isLoading && data.length && value === null && dafaultValue) {
        let fstate = data.filter((state) => {
          return state.label === dafaultValue;
        });
        if (fstate.length > 0 && fstate[0].label === dafaultValue)
          handleChange(fstate[0], true)
        else
          handleChange({label:null, value:null}, true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isLoading, value]);
    return (
      <SC.State
        value={isLoading ? null : data.filter((state) => state.value === value)}
        isLoading={isLoading}
        classNamePrefix="react-select-virtualized"
        isLatam={isLatam}
        options={isLoading ? [] : data}
        placeholder={placeholder}
        isClearable={false}
        onChange={(value) => handleChange(value)}
      />
    );
  },
  `states?region=${process.env.REACT_APP_REGION}`,
  false,
  false,
  ["country"]
);

const CitySelect = withFetch(
  ({
    country,
    state,
    data,
    isLoading,
    getData,
    value,
    placeholder,
    handleChange,
  }) => {
    useEffect(() => {
      if (country && state)
        getData(`cities?region=${process.env.REACT_APP_REGION}`);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country, state]);

    return (
      <SC.District
        value={isLoading ? null : data.filter((city) => city.value === value)}
        isLoading={state !== null && isLoading}
        classNamePrefix="react-select-virtualized"
        options={isLoading || state === null ? [] : data}
        placeholder={placeholder}
        isClearable={false}
        onChange={(value) => handleChange(value)}
      />
    );
  },
  `cities?region=${process.env.REACT_APP_REGION}`,
  false,
  false,
  ["country", "state"]
);
moment.locale(process.env.REACT_APP_REGION === "br" ? "br" : "mx");

const ShowtimeCont = ({
  setfilters,
  filters,
  setActiveGeo,
  activeGeo,
  title,
  show,
  isLoadingThreates,
  threates,
  getThreateInfo,
  activeDay,
  setActiveDay,
  openAccordion,
  setOpenAccordion,
  getShowTimes,
  formats,
  shouldRend,
  loadedGeo,
  resetLocationFilters,
}) => {
  const [initialized, setInitialized] = useState(filters.dafaultState === null ? true : filters.state === null ? false : true);
  const [filtersHeight, setFiltersHeight] = useState(0);
  //selectedTime lo uso para seleccionar el horario de la peli
  const [selectedTime, setselectedTime] = useState([-1, -1, -1]);
  const filtersRef = useRef(null);
  const scrollbarRef = useRef(null)

  const isLatam = process.env.REACT_APP_REGION === "latam";

  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 961px)").matches);

  useEffect(() => {
    let mediaQuery = window.matchMedia("(min-width: 961px)");
    const listener = () => setIsDesktop(mediaQuery.matches);
    mediaQuery.addEventListener("change",listener);
    return () => mediaQuery.removeEventListener("change",listener);
  }, []);

  useEffect(() => {
    const onResize = () => {
      if (filtersRef.current.offsetHeight !== filtersHeight)
        setFiltersHeight(filtersRef.current.offsetHeight);
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  });
  
  useEffect(() => {
    getShowTimes(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filters.state, filters.city]);

  useEffect(() => {
    if(openAccordion !== -1){
      let item = scrollbarRef.current.contentElement.firstChild.children;
      if(isDesktop){
        var heightTotal = 0;
        for (var i = 0; i < openAccordion; i++) {
          heightTotal = heightTotal + item[i].firstChild.offsetHeight + 6;
        }
        setTimeout(()=>{
          let parentElement = scrollbarRef.current.contentElement.parentElement;
          parentElement.scrollTo({ behavior: 'smooth', left: 0, top: heightTotal });
          setTimeout(()=>{
              let rect = item[openAccordion].getBoundingClientRect();
              if(rect.top < 0 || rect.bottom > window.innerHeight)
                window.scrollTo({ behavior: 'smooth', left: 0, top: document.documentElement.scrollTop + (rect.top < 0 ? rect.top : rect.bottom - window.innerHeight) });
          }, 300);
        },300)
      }else{
        setTimeout(()=>{
          let rect = item[openAccordion].getBoundingClientRect();
          if(rect.top < 0 || rect.bottom > window.innerHeight)
            window.scrollTo({ behavior: 'smooth', left: 0, top: document.documentElement.scrollTop + (rect.top < 0 ? rect.top : rect.bottom - window.innerHeight) });
        },300)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[openAccordion]);

  let t = useLanguage("showtimeCont");

  const handleClickCalendar = (index) => {
    setActiveDay(index);
    setselectedTime([-1, -1, -1]);
  };

  const handleClickAccordion = (index) => {
    setOpenAccordion(index === openAccordion ? -1 : index);
  };

  const handleClickTime = (index, indexShow, indexTime) => {
    if (
      selectedTime[0] === index &&
      selectedTime[1] === indexShow &&
      selectedTime[2] === indexTime
    )
      setselectedTime([-1, -1, -1]);
    else setselectedTime([index, indexShow, indexTime]);
  };

  const handleChangeCountry = (value) => {
    setActiveGeo(false);
    setfilters({
      ...filters,
      country: value.value,
      state: null,
      dafaultState: null,
      city: null,
      format: "all",
    });
  };

  const handleChangeState = (value, initial = false) => {
    if(value.value !== filters.state){
      if (filters.dafaultState !== value.label) setActiveGeo(false);
      setfilters({
        ...filters,
        state: value.value,
        city: initial && filters.dafaultState === value.label ? filters.dafaultCity : null,
        format: "all",
      });
    }
    if(initial)
      setInitialized(true)
  };

  const handleChangeCity = (value) => {
    if(filters.city !== value.value){
      setActiveGeo(false);
      setfilters({ ...filters, city: value.value, format: "all" });
    }
  };

  const handleChangeFormat = (value) => {
      setfilters({ ...filters, format: value.value });
  }

  const getItems = (data, index) => {
    let dataT = getThreateInfo(data.theatersId);
    return (
      <ShowtimeAccordion
        key={index}
        open={openAccordion === index}
        handleClickAccordion={() => handleClickAccordion(index)}
        handleClickTime={(indexShow, indexTime) =>
          handleClickTime(index, indexShow, indexTime)
        }
        selectedTime={ selectedTime[0] === index ? [selectedTime[1], selectedTime[2]] : [-1, -1] }
        name={dataT.name}
        address={dataT.address}
        avatar={dataT.avatar}
        showstime={data.data}
        lat={dataT.lat}
        lng={dataT.lng}
        selectedDate={ threates.length ? threates[activeDay].date : "" }
        title={title}
        activeGeo={activeGeo}
        formatFilter={filters.format}
      />
    );
  };  
  return (
    <SC.Container>
      {show && <SC.MovieTitle>{title}</SC.MovieTitle>}
      <SC.Filters ref={filtersRef}>
        <SC.Title>{t("title")}</SC.Title>
        {isLatam && (
          <CountrySelect
            value={filters.country}
            placeholder={t("country")}
            handleChange={handleChangeCountry}
          />
        )}
        <StateSelect
          isLatam={isLatam}
          country={filters.country}
          value={filters.state}
          dafaultValue={filters.dafaultState}
          placeholder={t("state")}
          handleChange={handleChangeState}
          setInitialized={setInitialized}
        />
        <CitySelect
          country={filters.country}
          state={filters.state}
          value={filters.city}
          placeholder={t("district")}
          handleChange={handleChangeCity}
        />
        <SC.MovieFormat
          classNamePrefix="react-select-virtualized"
          options={formats}
          placeholder={t("movieFormat")}
          isClearable={false}
          isLoading={isLoadingThreates && filters.state !== null && filters.city !== null}
          onChange={(value)=>handleChangeFormat(value)}
        />
        {!activeGeo && loadedGeo && <SC.BtnLocation onClick={()=>resetLocationFilters()}>{t("resetLocationBtn")}</SC.BtnLocation>}
        <SC.Calendar>
          {(shouldRend && filters.state !== null && filters.city !== null)?(threates.map((show, index) => {
            let formatDate = moment(show.date, "MM/DD/YYYY");
            return (
              <SC.DayCont key={index}>
                <SC.Month>{formatDate.format("MMM")}</SC.Month>
                <SC.Day>{formatDate.format("ddd")}</SC.Day>
                <SC.Number
                  className={index === activeDay ? "active" : ""}
                  onClick={() => handleClickCalendar(index)}
                >
                  {formatDate.format("D")}
                </SC.Number>
              </SC.DayCont>
            );
          })):null}
        </SC.Calendar>
      </SC.Filters>
      { isLoadingThreates && (!initialized || (filters.state !== null && filters.city !== null)) && <Loader background={false} widthImage={{ desk: 90, tablet: 90, mobile: 90 }} textSize={{ desk: 16, tablet: 16, mobile: 16 }}/> }
      { initialized && (filters.state === null || filters.city === null) && <SC.NoData>{t("useTheFilters")}</SC.NoData>}
      { !isLoadingThreates && initialized && filters.state !== null && filters.city !== null && ( !shouldRend || threates.length === 0 || threates[activeDay].showtime.length === 0) && <SC.NoData>{t("noData")}</SC.NoData> }
      { !isLoadingThreates && initialized && filters.state !== null && filters.city !== null && shouldRend && threates[activeDay] && threates[activeDay].showtime.length !== 0 && (
        <SC.ScrollbarStyled
          translateContentSizeYToHolder
          noScrollX
          filtersheight={filtersHeight}
          ref={scrollbarRef}
        >
          <SC.AccordionCont >
            {threates[activeDay].showtime.map(getItems)}
          </SC.AccordionCont>
        </SC.ScrollbarStyled>
      ) }

    </SC.Container>
  );
};

export default ShowtimeCont;
