import React from "react";
import * as SC from "./styled";
import { useLanguage } from '../../context/Language'

const Footer = () => {
  let t = useLanguage("footer");
  
  const getLinks = (name, Li, Lnk, Ul) =>{
    let txtLinks = t(name);
    let links = txtLinks.map((lnk, index) => {
      return <Li key={`${name}-${index}`}><Lnk href={lnk.href}>{lnk.text}</Lnk></Li>
    });
    return (<Ul>{links}</Ul>)
  }
  
  return (
    <SC.Container>
      <SC.NavContainer>
        <SC.LogoDisney />
        <SC.Nav>{getLinks("navLinks", SC.Li, SC.Lnk, SC.Ul)}</SC.Nav>
      </SC.NavContainer>
      {getLinks("legal", SC.LiTyC, SC.LnkTyC, SC.UlTyC)}
      <SC.Copyright>{t('copyright')}</SC.Copyright>
    </SC.Container>
  );
};

export default Footer;