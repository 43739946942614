import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import MovieCardList from "../MovieCardList";
import withFetch from "../../hocs/withFetch";


const NextReleases = ({onSucess, data, isLoading}) => {
  useEffect(() => {
    if(!isLoading)
      onSucess()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  if(!isLoading && data.errorType && data.errorType === "CountryNotFound")
    return <></>
  else
    return (<MovieCardList sectionName="proximos-estrenos" isLoading={isLoading} data={data} external={true} />);
};

NextReleases.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
        href: PropTypes.string,
      })
    ),
    PropTypes.objectOf(PropTypes.string)
  ]),
  isLoading: PropTypes.bool
}
/*
NextReleases.defaultProps = {
  data: [
    {
      image: process.env.PUBLIC_URL+"/images/home-movie-coming-soon-1.png",
      title: "JUNGLE CRUISE",
      href: "https://disneylatino.com/peliculas/soul",
    },
    {
      image: process.env.PUBLIC_URL+"/images/home-movie-coming-soon-2.png",
      title: "THE KING’S MAN",
      href: "https://disneylatino.com/peliculas/soul",
    },
    {
      image: process.env.PUBLIC_URL+"/images/home-movie-coming-soon-3.png",
      title: "FREE GUY",
      href: "https://disneylatino.com/peliculas/soul",
    }
  ]
};
*/
export default React.memo(withFetch(NextReleases, `future-releases?region=${process.env.REACT_APP_REGION}`, true, false, [], true));