import React, { createContext, useContext, useMemo } from 'react';
import languageDictionary from '../languages';

export const LanguageContext = createContext({
  dictionary: languageDictionary,
  getTexts:(compName) => (key)=> compName+'.'+key
});

export function LanguageProvider(props) {
  const languageContext = useContext(LanguageContext);

  const provider = {
    dictionary: languageContext.dictionary,
    getTexts:(compName) => (key)=> languageContext.dictionary[compName][key]
  };

  const { children } = props;

  return <LanguageContext.Provider value={provider}>{children}</LanguageContext.Provider>;
}

export function useLanguage(compName) {
  let { getTexts } = useContext(LanguageContext);
  let t = useMemo(() => getTexts(compName), [compName, getTexts]);
  return t;
}
