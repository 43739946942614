import React, { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Map from "../Map";
import ShowtimeCont from "../ShowtimeCont";
import { GeoContext } from "../../context/Geotargeting";
import { useParams } from "react-router-dom";
import withFetch from "../../hocs/withFetch";

const DEFAULT_ACTIVE_DAY = 0;
const DEFAULT_ACTIVE_CINEMA = -1;

const ShowTimeAndMap = (props) => {
  const { country_code, state, city, loaded, latitude, longitude } = useContext(GeoContext);
  const { title, data, setFullscreen, fullscreen, getData, isLoading } = props;
  //filters
  const [locationFilters, setLocationFilters] = useState({
    country: country_code,
    state: null,
    dafaultState: state,
    city: city,
    dafaultCity: city,
    format: "all",
  });

  const [activeDay, setActiveDay] = useState(DEFAULT_ACTIVE_DAY);
  const [activeCinema, setActiveCinema] = useState(DEFAULT_ACTIVE_CINEMA);
  const [activeGeo, setActiveGeo] = useState(true);
  let { slug } = useParams();
  var shouldFetch = useRef(true);

  const handleSetActiveDay = (index) => {
    if (activeDay !== index) {
      setActiveDay(index);
      setActiveCinema(DEFAULT_ACTIVE_CINEMA);
    }
  };

  //const isFirstRender = React.useRef(true);

  const getShowTimes = (force = false) =>{
    if ((isLoading && shouldFetch.current) || force) {
        if (activeGeo && loaded && shouldFetch.current){
            getData(
              `movies/${slug}/showtimes?lat=${latitude}&lon=${longitude}&region=${process.env.REACT_APP_REGION}`
            );
            shouldFetch.current = false;
        }else if((!activeGeo || !loaded) && locationFilters.state != null && locationFilters.city != null){
            getData(
                `movies/${slug}/showtimes?country=${locationFilters.country}&state=${locationFilters.state}&city=${locationFilters.city}&region=${process.env.REACT_APP_REGION}`
              );
              shouldFetch.current = false;
        }
      }
  }

  useEffect(() => {
    getShowTimes(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleDisableGeo = (value) => {
    if (activeGeo !== value) setActiveGeo(value);
  };

  const getThreateInfo = (id) => {
    if (data && data.theaters && data.theaters.length) {
      return data.theaters.find((t) => t.id === id);
    }
    return null;
  };

  const resetLocationFilters = () => {
    shouldFetch.current = true;
    setActiveGeo(true);
    setLocationFilters({
      country: country_code,
      state: null,
      dafaultState: state,
      city: city,
      dafaultCity: city,
      format: "all",
    });
    setActiveDay(DEFAULT_ACTIVE_DAY);
    setActiveCinema(DEFAULT_ACTIVE_CINEMA);
  }

  let auxShowstimes = {}
  if(data.showstimes){
    auxShowstimes = (locationFilters.format !== "all")?data.showstimes.map(e => {
        let auxResult = {};
        auxResult.date = e.date;
        auxResult.showtime = e.showtime.filter(s => ( s.data.find(d => d.format.replace(' ', '-') === locationFilters.format)));
        return auxResult;
    }): data.showstimes;
  }  
  
  return (
    <>
        <ShowtimeCont
          setfilters={setLocationFilters}
          filters={locationFilters}
          setActiveGeo={handleDisableGeo}
          activeGeo={activeGeo}
          title={title}
          isLoadingThreates={isLoading}
          threates={auxShowstimes}
          show={fullscreen.open}
          activeDay={activeDay}
          setActiveDay={handleSetActiveDay}
          openAccordion={activeCinema}
          formats={data.formats && locationFilters.state !== null && locationFilters.city !== null ?data.formats:[{label: "Todos", value: "all"}]}
          setOpenAccordion={setActiveCinema}
          getThreateInfo={getThreateInfo}
          getShowTimes={getShowTimes}
          shouldRend={!isLoading && data.showstimes ? true :  false}
          loadedGeo={loaded}
          resetLocationFilters={resetLocationFilters}
        />
      <Map
        data={
          isLoading || locationFilters.state === null || locationFilters.city === null ? {} : data.showstimes && auxShowstimes[activeDay]
            ? auxShowstimes[activeDay] : {}
        }
        getThreateInfo={getThreateInfo}
        activeGeo={activeGeo}
        fullscreen={fullscreen}
        setFullscreen={setFullscreen}
        activeCinema={activeCinema}
        setActiveCinema={setActiveCinema}
        resetLocationFilters={resetLocationFilters}
      />
    </>
  );
};

ShowTimeAndMap.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  isLoading: PropTypes.bool,
  title: PropTypes.string,
};


export default withFetch(ShowTimeAndMap, "", false, false);
