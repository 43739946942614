import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withFetch from "../../hocs/withFetch";
import { useLanguage } from '../../context/Language';
import Loader from '../Loader/loaderScreen';
import {Link} from 'react-router-dom';

import * as SC from "./styled";

const Lnk = ({redirectTo, children}) => {

  if(redirectTo === "")
    return <div>{children}</div>
  
  if (redirectTo.includes('http') || redirectTo.includes('www.'))
    return <a href={redirectTo}>{children}</a>
  
    return <Link to={redirectTo}>{children}</Link>
}

const BannerHome = ({onSucess, isLoading, data, ...other}) => {
  useEffect(() => {
    if(!isLoading)
      onSucess()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  let t = useLanguage("home");
  if (isLoading)
    return (
      <SC.Container><SC.ContainerLoading><Loader background={false} widthImage={{ desk: 90, tablet: 90, mobile: 90 }} textSize={{ desk: 16, tablet: 16, mobile: 16 }}/></SC.ContainerLoading></SC.Container>
    )
  if(data && data.length){
    let {image, title, desc, btnViewMore, redirectTo} = data[0];
    return (
      <SC.Container>
        <Lnk redirectTo={redirectTo}>
          <SC.ContImg>
            <img src={image.desk} className="banner-home-desk" alt={title} />
            <img src={image.tablet} className="banner-home-tablet" alt={title} />
            <img src={image.mobile} className="banner-home-mobile" alt={title} />
          </SC.ContImg>
          <SC.Content>
            { title && <SC.Title dangerouslySetInnerHTML={{__html: title}}/>}
            { desc.desktop && <SC.Desc className="description-desktop" dangerouslySetInnerHTML={{__html: desc.desktop}}></SC.Desc>}
            { desc.tablet && <SC.Desc className="description-tablet" dangerouslySetInnerHTML={{__html: desc.tablet}}></SC.Desc>}
            { desc.mobile && <SC.Desc className="description-mobile" dangerouslySetInnerHTML={{__html: desc.mobile}}></SC.Desc>}
            { btnViewMore && <SC.ViewMore><span>{t('ver-mas')}</span></SC.ViewMore>}
          </SC.Content>
        </Lnk>
      </SC.Container>
    );
  }else{
    return (<></>)
  }
};

BannerHome.propTypes = {
  data:  PropTypes.arrayOf( PropTypes.shape({
    title: PropTypes.string,
    desc: PropTypes.shape({
      desktop: PropTypes.string,
      tablet: PropTypes.string,
      mobile: PropTypes.string
    }),
    image: PropTypes.shape({
      desk: PropTypes.string,
      tablet: PropTypes.string,
      mobile: PropTypes.string
    }).isRequired,
    btnViewMore: PropTypes.bool,
    redirectTo: PropTypes.string.isRequired
  }))
  
};

/*
BannerHome.defaultProps = {
  title: "Próximamente",
  desc: {
    desktop: "A partir del <b>5 de Octubre</b> ya podes tener tus entradas para no perderte <b>BLACK WIDOW</b>",
    tablet: "A partir del <b>5 de Octubre</b> ya podes tener tus entradas para no perderte <b>BLACK WIDOW</b>",
    mobile: "A partir del <b>5 de Octubre</b> ya podes tener tus entradas para no perderte <b>BLACK WIDOW</b>",
  },
  image: {
    desk: process.env.PUBLIC_URL+"/images/banner-home-desk.png",
    tablet: process.env.PUBLIC_URL+"/images/banner-home-tablet.png",
    mobile: process.env.PUBLIC_URL+"/images/banner-home-mobile.png"
  },
  btnViewMore: true,
  redirectTo: process.env.PUBLIC_URL+'/black-widow'
}
*/

export default withFetch(BannerHome, `featured?region=${process.env.REACT_APP_REGION}`, true, false, [], true);