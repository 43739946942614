import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Header from '../Header';
import Footer from '../Footer';
import Snackbar from '../Snackbar';
import AdsHeader from "../AdsHeader";
import AdsFooter from "../AdsFooter";

const Container = styled.div`
  @media screen and (min-width: 1600px) {
    max-width: 1600px;
    margin: 0 auto;
  }
`;

const AdsTop = styled.div`
    position: static;
    top: 0px;
    width: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    @media screen and (min-width: 426px) {
      position:sticky;
    }
    & #banner-ad-728x90{
    width: 100%;
    box-sizing: border-box;
    padding: 25px 0px;
    background: transparent linear-gradient(181deg,#0077DA 0%,#183663 100%) 0% 0% no-repeat;
    text-align: center;
    }
`;
const AdsBottom = styled.div`
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding-top: 20px;
    z-index: 4;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    @media screen and (min-width: 48em) {
      position: static;
    }
    & #banner-ad-970x66{
    }
`;

const Layout = ({ children }) => {
  return (
    <Container>
      <Header />
      <AdsTop>
        <AdsHeader />
      </AdsTop>
      <main>{children}</main>
      <AdsBottom>
        <AdsFooter />
      </AdsBottom>
      <Footer/>
      <Snackbar/>
    </Container>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;