import styled from "styled-components";
import ArrowS from "../../assets/images/slider_arrow.svg"


export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: ${props => props.maxWidth.mobile};
  @media (min-width: 601px) {
    max-width: ${props => props.maxWidth.tablet};
    margin: 0 auto;
  }
  @media (min-width: 961px) {
    max-width: ${props => props.maxWidth.desk};
  }
`;

export const Arrow = styled.div`
  position: absolute;
  cursor: pointer;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  z-index: 99;
  background:url(${ArrowS}) no-repeat 55% center #FFF;
  background-size: 24px 35px;
  filter: drop-shadow(0 0 4px rgba(0,0,0,0.16));
  transition: opacity 333ms;
  opacity: 1;
  &:hover {
    opacity: 0.7;
    background-color: #F1F2F3;
  }
`;

export const LeftArrow = styled(Arrow)`
  display: ${props => props.hide ? "none" : "block"};
  transform: rotate(180deg);
  left: -22px;
  top: calc(50% - 22px);
`;

export const RightArrow = styled(Arrow)`
  display: ${props => props.hide ? "none" : "block"};
  right: -22px;
  top: calc(50% - 22px);
`;
