import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation 
} from "react-router-dom";
import GlobalFonts from './assets/fonts/fonts';
import { LanguageProvider } from './context/Language';
import { GeoProvider } from './context/Geotargeting';
import Layout from './components/Layout';
import Home from './screens/home.js';
import Movie from './screens/movie.js';
import NotFound from './screens/notFound.js';
import withFetch from "./hocs/withFetch";
import { GeoContext } from './context/Geotargeting';

const baseHref = process.env.PUBLIC_URL;

const Root = withFetch(({loadedCmp, getData, data, isLoading }) => { 
  let [utagLoaded, setUtagLoaded] = useState(false);
  let [countryNotFound, setCountryNotFound] = useState(false);
  let [loadedGeo, setLoadedGeo] = useState(false);
  const {setGeoByIp} = useContext(GeoContext);
  var shouldFetch = useRef(true);

  useEffect(() => {
    if(loadedCmp && isLoading && shouldFetch.current){
        getData(`country-validate?region=${process.env.REACT_APP_REGION}`);
        shouldFetch.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loadedCmp]);

  useEffect(() => {
    let mounted = true;
    if(mounted && !isLoading && !loadedGeo && data.errorType && data.errorType === "CountryNotFound"){
        setCountryNotFound(true)
    }
    if(!loadedGeo && !isLoading){
      if(data.errorType && data.errorType === "CountryNotFound"){
        setGeoByIp({ city: null, country: null, state: null, zipcode: null, countryNotFound: true, country_code: null })
      }else{
        setGeoByIp({ 
          city: data.city ? data.city : null, 
          country: data.country, 
          state: data.state ? data.state : null, 
          zipcode: data.zipcode, 
          countryNotFound: false, 
          country_code: data.country_code 
        })
      }
      setLoadedGeo(true);
    }
    return () => mounted = false;
  }, [isLoading, data, loadedGeo, setGeoByIp]);

  let location = useLocation();

  useEffect(() => {
    if (utagLoaded)
      window.utag.view(window.pageData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if(!loadedGeo && !isLoading && !data.errorType){
      window.utag_script();
      setUtagLoaded(true);
    }
  }, [loadedGeo, isLoading, data.errorType]) 

  const hideLoading = loadedCmp && !isLoading && loadedGeo;
  return ( 
      <Switch>
        <Route exact path="/" children={<Home hideLoading={hideLoading} city={data && data.city ? data.city : null} countryNotFound={countryNotFound} />} />
        <Route exact path="/404" children={<NotFound />} />
        <Route exact path="/:slug" children={<Movie hideLoading={hideLoading} countryNotFound={countryNotFound} /> } />
        <Route exact path="*" children={<NotFound />} />
      </Switch>
  ) 
}, "", false, false,["coordinates"]);


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {

  let [loadedCmp, setLoadedCmp] = useState(false);
  
  useEffect(() => {
    setTimeout(()=>setLoadedCmp(true),600)
    // eslint-disable-next-line react-hooks/exhaustive-deps
 },[]);

  return (
    <Router basename={baseHref}>
      <GlobalFonts />
      <ScrollToTop />
      <LanguageProvider>
        <GeoProvider>
          <Layout>
            <Root loadedCmp={loadedCmp}/>
          </Layout>
        </GeoProvider>
      </LanguageProvider>
    </Router>
  );
}

export default App;
