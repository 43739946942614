import React from "react";
import styled from "styled-components";
import {Link, useLocation } from "react-router-dom";
import logo from  "../../assets/images/disney-tickets-logo-header.svg";

const Container = styled.header`
  text-align:center;
  padding: 20px 0 27px;
  @media (min-width: 601px) {
    padding: 21px 0 22px;
  }
  @media (min-width: 961px) {
    padding: 35px 0 41px;
  }
`;

const Lnk = styled(Link)`
  display: inline-block;
  margin: 0 auto;
  text-decoration: none;
  cursor: ${props=> props.ishome ? 'auto':'pointer'};
  vertical-align: middle;
  & .logo{
    display: block;
  }
  @media (max-width: 600px) {
    & .logo{
      max-width: 99px;
    }
  }
`;

const Header = () => {
  let location = useLocation();
  var propLnk;
  if(location.pathname === '/')
    propLnk = { as: "span", ishome: true}
  else
    propLnk = { to: "/" };

  return (
    <Container>
      <Lnk {...propLnk} ><img src={logo} className="logo" alt="Disney Tickets"/></Lnk>
    </Container>
  );
};

export default Header;