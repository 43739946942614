import styled, { keyframes } from "styled-components";
import { ReactComponent as MarkerIcon } from '../../assets/images/marker.svg';
import { ReactComponent as MarkerCineIcon } from '../../assets/images/marker-cine-default.svg';
import fullscreenIcon from  "../../assets/images/fullscreen.png";
import closeIcon from  "../../assets/images/close.png";
import fullscreenMobileIcon from  "../../assets/images/fullscreen-mobile.png";
import closeMobileIcon from  "../../assets/images/close-mobile.png";
import zoomInIcon from  "../../assets/images/zoom-in.png";
import zoomOutIcon from  "../../assets/images/zoom-out.png";
import locationIcon from  "../../assets/images/location.png";
import locationMobileIcon from  "../../assets/images/location-mobile.png";

export const Container = styled.section`
  position: relative;
  order: 1;
  width: 100%;
  box-sizing: border-box;
  & a[href^="http://maps.google.com/maps"]{display:none !important}
  & a[href^="https://maps.google.com/maps"]{display:none !important}
  & .gmnoprint a, .gmnoprint span, .gm-style-cc {
      display:none;
  }
  & .gmnoprint div {
      background:none !important;
  }
  @media (max-width: 600px) {
    height: 180px;
  }
  @media (min-width: 601px) and (max-width: 960px) {
    height: 270px;
  }
  @media (max-width: 960px) {
    min-height: ${props => props.fullscreen.open ? '100vh' : 'auto'};
  }
  @media (min-width: 961px) {
    flex: ${props => props.fullscreen.open ? '0 0 58,8125%' : '0 0 29.4375%'};
    padding: 0px;
    order: 2;
    min-height: ${props => props.fullscreen.open ? props.fullscreen.minHeight : 'auto'};
    & .stickyCont {
      height: 100vh;
    }
  }
`;

const animatedMarker = keyframes`
  0% {
       transform: translate(-50%,-50%) translateY(0) scale(.8)
  }

  50%,to {
      transform: translate(-50%,-50%) translateY(0) scale(1)
  }

  25%,75% {
    transform: translate(-50%,-50%) translateY(14px) scale(1)
  }
`;

export const MarkerIconStyled = styled(MarkerIcon)`
  @media (max-width: 960px) {
    width: 35px;
    height: 50px;
  }
`;
export const MarkerCineIconStyled = styled(MarkerCineIcon)`
  &:hover .cls-1{
    fill:#005398;
  }
  @media (max-width: 960px) {
    width: 35px;
    height: 40px;
  }
`;

export const MarkerCont = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  font-family: 'NotoSansSemiBold';
  font-size: 12px;
  color: #1D1E1F;
  min-width: 100px;
  text-align: center;
  cursor: pointer;
  &.clickedIcon {
    animation: ${animatedMarker} .8s both;
    .cls-1, #ic_person_pin_circle_24px{
      fill:#005398;
    }
  }
  &:hover{
    color: #000;
    font-size: 13px;
  }

  & ${MarkerIconStyled},
  & ${MarkerCineIconStyled} {
    display: block;
    margin: 0 auto;
  }
`;

export const ButtonStyled = styled.button`
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  border-radius: 50%;
  width: 29px;
  height: 29px;
  padding: 0;
  display: block;
  cursor: pointer;
  z-index: 99;
  box-shadow: 0 3px 6px rgba(0,0,0,.16);
  @media (min-width: 601px) {
    width: 44px;
    height: 44px;
  }
`;

export const FullScreenIcon = styled(ButtonStyled)`
  right: 33px;
  bottom: ${props => props.fullscreen ? 'auto' : '6px'};
  top: ${props => props.fullscreen ? '31px' : 'auto'};
  background: ${props => props.fullscreen ? `url(${closeMobileIcon}) center center #0077DA no-repeat` : `url(${fullscreenMobileIcon}) center center #0077DA no-repeat`};
  &:hover {
    background-image: ${props => props.fullscreen ? `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${closeMobileIcon})` : `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${fullscreenMobileIcon})`};
  }
  @media (min-width: 601px) {
    right: 40px;
    bottom: ${props => props.fullscreen ? 'auto' : '26px'};
    top: ${props => props.fullscreen ? '31px' : 'auto'};
    background: ${props => props.fullscreen ? `url(${closeIcon}) center center #0077DA no-repeat` : `url(${fullscreenIcon}) center center #0077DA no-repeat`};
    &:hover {
      background-image: ${props => props.fullscreen ? `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${closeIcon})` : `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${fullscreenIcon})`};
    }
  }
  @media (min-width: 961px) {
    right: 27px;
    bottom: auto;
    top: 31px;
  }
`;

export const ZoomInIcon = styled(ButtonStyled)`
  display: ${props => props.fullscreen ? 'block' : 'none'};
  top: 31px;
  left: 31px;
  background: url(${zoomInIcon}) center center #0077DA no-repeat;
  &:hover {
    background-image:linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${zoomInIcon});
  }
`;

export const ZoomOutIcon = styled(ButtonStyled)`
  display: ${props => props.fullscreen ? 'block' : 'none'};
  top: 31px;
  left: 72px;
  background: url(${zoomOutIcon}) center center #0077DA no-repeat;
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${zoomOutIcon});
  }
  @media (min-width: 601px) {
    left: 92px;
  }
`;

export const LocationIcon = styled(ButtonStyled)`
  top: ${props => props.fullscreen ? '31px' : '6px'};
  left:  ${props => props.fullscreen ? '112px' : '31px'};
  background: url(${locationMobileIcon}) center center #0077DA no-repeat;
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${locationMobileIcon});
  }
  @media (min-width: 601px) {
    top: ${props => props.fullscreen ? '31px' : '26px'};
    left:  ${props => props.fullscreen ? '152px' : '31px'};
    background: url(${locationIcon}) center center #0077DA no-repeat;
    &:hover {
      background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${locationIcon});
    }
  }
`;
