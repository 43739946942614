import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import * as SC from "./styled";
//import withFetch from "../../hocs/withFetch";
import { useLanguage } from '../../context/Language';
//import { useParams } from "react-router-dom";
import moment from 'moment';

const MovieDetails = forwardRef(({ hide, isLoading, data: {poster, title, releaseYear, synopsis, directors, topCast, classification, duration}}, ref) => {
  const [open, setOpen] = useState(true);
  let t = useLanguage("movieDetails");

  const handleClick = () => {
    setOpen(!open)
  }

  if (isLoading)
    return <SC.Container></SC.Container>;
  
  let durationMoment = moment.duration(duration);

  return(
    <SC.Container ref={ref} hide={hide} className={ open ? "show" : "hide" }>
      <SC.ContHeader>
        <SC.Poster><img src={poster} alt={title} /></SC.Poster>
        <SC.Title>{title}</SC.Title>
        <SC.Year>{t("year")} {moment(releaseYear,"DD-MM-YYYY").year()}</SC.Year>
        <SC.Arrow onClick={handleClick}/>
      </SC.ContHeader>
      <SC.ContText>
        <SC.ContInline>
          <SC.Classification>{classification}</SC.Classification>
          { (durationMoment.get('h') !== 0 || durationMoment.get('m') !== 0) && <SC.Duration><span className="hideDesktop">{t("duration")}:</span> {durationMoment.get('h')}h {durationMoment.get('m')}min</SC.Duration>}
        </SC.ContInline>
        <SC.SubTitle>{t("synopsis")}</SC.SubTitle>
        <SC.Text dangerouslySetInnerHTML={{__html: synopsis}} />
        <SC.SubTitle>{t("director")}</SC.SubTitle>
        <SC.Text>{directors.join(", ")}.</SC.Text>
        <SC.SubTitle>{t("topCast")}</SC.SubTitle>
        <SC.Text>{topCast.join(", ")}.</SC.Text>
      </SC.ContText>
    </SC.Container>
  )
})

MovieDetails.propTypes = {
  hide: PropTypes.bool,
  data: PropTypes.oneOfType([
    PropTypes.shape({
      status: PropTypes.string,
      poster: PropTypes.string,
      title: PropTypes.string,
      releaseYear: PropTypes.string,
      synopsis: PropTypes.string,
      directors: PropTypes.arrayOf(PropTypes.string),
      topCast: PropTypes.arrayOf(PropTypes.string),
      duration: PropTypes.string,
      classification: PropTypes.string
    }),
    PropTypes.objectOf(PropTypes.string),
    PropTypes.array
  ]),
  isLoading: PropTypes.bool,
  getData: PropTypes.func
}
/*
MovieDetails.defaultProps = {
  poster: 'https://develop.disneytickets.w3americas.com/latam/images/black-widow-poster.jpg',
  title: 'BLACK WIDOW',
  releaseYear: "2020",
  synopsis: "El thriller de espías de <b>Marvel Studios<sup>TM</sup></b> presenta la historia de Natasha Romanoff, también conocida como <b>Black Widow</b>. Ella se enfrenta a lo más oscuro de sus cuentas pendientes cuando surge una peligrosa conspiración que tiene lazos con su pasado. Perseguida por una fuerza que no se detendrá ante nada para derribarla, debe lidiar con su historia como espía y con las relaciones rotas que dejó a su paso mucho antes de convertirse en parte de los Vengadores.<br /><br />Scarlett Johansson vuelve como Natasha/<b>Black Widow</b>, Florence Pugh interpreta a Yelena, David Harbour interpreta a Alexei/The Red Guardian y Rachel Weisz es Melina.<br /><br />Black Widow, la primera película en la Fase Cuatro del Universo Cinematográfico de MarvelTM, dirigida por Cate Shortland y producida por Kevin Feige, llega muy pronto a Latinoamérica.",
  directors: ["Cate Shortland"],
  topCast: ["Scarlett Johansson", "Rachel Weisz", "David Harbour", "Florence Pugh"],
  classification: "M - 12",
  duration: "1h 20 min"
}
*/
//export default withFetch(MovieDetails, "", false, false );
export default MovieDetails;
