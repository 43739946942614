export function trackEvent(actionName, actionValue, cinema, film, type, date, hour) {
  var linkData = {
    link_name: 'tE',
    actionName: actionName,
    actionValue: actionValue,
    cinema: cinema,
    film: film,
    type: type,
    date: date,
    hour: hour
  };
  window.utag.link(Object.assign({}, window.pageData, linkData))
}