import React, { useState, useEffect, useRef }  from 'react';
import styled from "styled-components";
import { useParams } from "react-router-dom";
import withFetch from "../hocs/withFetch";
import MovieDetails from "../components/MovieDetails";
import ShowTimeAndMap from "../components/ShowTimeAndMap";
import LoaderScreen from '../components/Loader/loaderScreen.js';
import { Redirect } from "react-router";

const Container = styled.div`
 display: flex;
 flex-flow: column-reverse;
 height: ${props => props.loading ? '50vh' : ''};
 @media (min-width: 961px) {
    flex-direction: row;
    border-bottom: ${props => props.loading === 'true' ? 'none' : '3px solid #DCDDDF'};
 }
`;

const Movie = (props) => {
  let [fullscreen, setFullscreen] = useState({open:false, minHeight: 'auto'});
  const movieRef = useRef(null);
  let { slug } = useParams();
  let reqUrl = `/movies/${slug}?region=${process.env.REACT_APP_REGION}`;

  const handleClickFullScreen = () => {
    setFullscreen({open: !fullscreen.open, minHeight: !fullscreen.open ? movieRef.current && movieRef.current.clientHeight+'px' : 'auto'})
  }

  useEffect(() => {
    if(props.isLoading)
      props.getData(reqUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(props.countryNotFound)
    return <Redirect to="/"/>;

  if (props.isLoading || !props.hideLoading)
    return <LoaderScreen  background={false} />;

  return (
    <Container>
      <MovieDetails ref={ movieRef } {...props} hide={fullscreen.open}/>
      <ShowTimeAndMap title={props.data.title} fullscreen={fullscreen} setFullscreen={handleClickFullScreen}/>
    </Container>
  );
};


export default withFetch(Movie, "", false, false );
