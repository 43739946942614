import styled from "styled-components";
import ArrowUp from "../../assets/images/arrow-up.svg";
import ArrowDown from "../../assets/images/arrow-down.svg"
import Clock from "../../assets/images/clock.svg"

export const Title = styled.h2`
  font-family: "NotoSansBold";
  font-size: 18px;
  line-height: 20px;
  color: #0070CC;
  margin: 0px;
  padding-top: 20px;
  opacity: 1;
  @media (min-width: 601px) {
    font-size: 28px;
    line-height: 32px;
    padding-top: 0px;
  }

  @media (min-width: 961px){
    margin-left: 40px;
    margin-right: 40px;
  }
`;

export const Arrow = styled.span`
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  right: 0;
  bottom: -22px;
  cursor: pointer;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  z-index: 99;
  background:url(${ArrowUp}) no-repeat center #F1F2F3;
  background-size: 16px 10px;
  filter: drop-shadow(0 3px 6px rgba(0,0,0,0.16));
  @media (max-width: 600px) {
    &:active {
      opacity: 0.7;
      background-color: #FFF;
    }
  }
  @media (min-width: 601px) {
    &:hover {
      opacity: 0.7;
      background-color: #FFF;
    }
  }
  @media (min-width: 961px) {
    display: none;
  }
`;

export const Year = styled.p`
  font-family: "NotoSansSemiBold";
  font-size: 14px;
  line-height: 19px;
  color: #1D1E1F;
  margin: 10px 0px 0px;

  @media (min-width: 601px) {
    font-size: 18px;
  }
  @media (min-width: 961px){
    font-size: 16px;
    margin-left: 40px;
    margin-right: 40px;
  }
`;

export const Poster = styled.div`
  float: left;
  width: 43.81%;
  margin: 0px 20px 20px 0;
  & img {
    width: 100%;
    max-width: 100%;
    display: block;
  }

  @media (min-width: 601px) {
    width: 48%;
  }

  @media (min-width: 961px) {
    width: 100%;
    max-width: 100%;
    margin: 0px 0px 40px;
    float: none;
  }
`;

export const ContText = styled.div`
`;

export const ContHeader = styled.div`
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  //margin-bottom: 55px;
  @media (min-width: 601px) {
    padding-top: 30px;
    padding-bottom: 10px;
  }

  @media (max-width: 960px) {
    &::before{
      content: '';
      position: absolute;
      top: 0px;
      left: -30px;
      right: -30px;
      bottom: 0px;
      background-color: #F1F2F3;
      z-index: -1;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 8px -4px inset;
      @media (min-width: 601px) {
        left: -40px;
        right: -40px;
      }
    }
  }
  @media (min-width: 961px) {
    padding-top: 0px;
    padding-bottom: 0px;
    //margin-bottom: 20px;
  }
`; 

export const Container = styled.section`
  width: 100%;
  position: relative;
  padding: 0px 30px 30px;
  box-sizing: border-box;

  @media (min-width: 601px) {
    padding: 0 40px 40px;
  }

  @media (min-width: 961px) {
    display: ${props => props.hide ? 'none' : 'block'};
    flex: 0 0 29.375%;
    padding: 0px;
    &:after{
      right: 0;
      content: "";
      border-right: 1px solid #DCDDDF;
      top: 0;
      bottom: 0;
      width: 0px;
      position: absolute;
      z-index: -1;
    }
  }

  @media (max-width: 960px){
    ${Year}, ${ContText}{
      max-height: 0;
      overflow: hidden;
      transition: 0.5s ease;
    }
    ${Poster}{
      opacity: 0;
      display: none;
    }
  }
  @media (max-width: 960px){
    &.show {
      ${Year}, ${ContText}{
        max-height: 1000vh;
        transition: 0.5s ease;
        overflow: visible;
      }

      ${Poster}{
        opacity: 1;
        display: block;
      }
    }
    &.hide {
      padding-bottom: 0px;
      ${Arrow}{
        background-image: url(${ArrowDown})
      }
      
      ${Title} {
        text-align: center;
      }
    }
  }
`;


export const ContInline = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 55px;
    white-space: pre;
    @media (min-width: 601px){
      margin-top: 40px;
    }
    @media (min-width: 961px){
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom: 30px;
      margin-top: 20px;
    }
`;

export const Classification = styled.span`
  font-family: "NotoSansSemiBold";
  font-size: 12px;
  line-height: 28px;
  color: #0077DA;
  border-radius: 6px;
  border: 1px solid #0077DA;
  display: inline-block;
  padding-left: 7px;
  padding-right: 7px;
  @media (min-width: 961px){
    font-size: 14px;
    line-height: 28px;
    border: 2px solid #0077DA;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Duration = styled.span`
  font-family: "NotoSansBold";
  font-size: 12px;
  line-height: 28px;
  &::before {
    content: "";
    display: inline-block;
    background: url(${Clock}) no-repeat center;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 5px;
    margin-left: 10px;
  }

  @media (max-width: 600px), (min-width: 961px) and (max-width: 1137px) {
    .hideDesktop {
      display: none
    }
  }

  @media (min-width: 601px){
    font-size: 14px;
    line-height: 28px;
  }
`;

export const SubTitle = styled.p`
  font-family: "NotoSansBold";
  font-size: 18px;
  line-height: 24px;
  color: #0077DA;
  margin: 0px 0px 15px;

  @media (min-width: 601px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (min-width: 961px){
    margin-left: 40px;
    margin-right: 40px;
  }
`;

export const Text = styled.p`
  font-family: "NotoSansLight";
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  color: #000;
  margin: 0px 0px 20px;

  @media (min-width: 601px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (min-width: 961px){
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 30px;
  }
`;