import React, { useRef, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import * as SC from "./styled";
import MovieCard from '../MovieCard';
import Slider from '../Slider';
import Loader from '../Loader/loaderScreen';
import { useLanguage } from '../../context/Language';

const MovieCardList = ({sectionName, external,  data, className, isLoading}) => {
  let t = useLanguage("home");
  let txt = t(sectionName);
  var movieCardContRef = useRef(null);

  //eliminar despues de integrar servicios
  /*
  let [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
  }, []);
  console.log(loading)
  */
  //hasta aca
  let [loading, setLoading] = useState(false);
  useEffect(() => {
    if (movieCardContRef.current) {
      setLoading(true)
    }
  }, [isLoading, data, movieCardContRef])
  
  const getMovieCard = () => {
    let elems = data.map((movie, index) => (
      <MovieCard 
        key={`movie-${index}`} 
        image={movie.image} 
        title={movie.title} 
        cta={{text: txt['text-link'], href:  external ? movie.href : `/${movie.slug}`, external: external}}
        sectionName={sectionName}
      />)
    );
    return elems;
  }

  const noMovieClassname = !isLoading && Array.isArray(data) && data.length === 0 ? sectionName+"-no-movie" : ""
  return (
    <SC.Container className={className+" "+noMovieClassname}>
      <SC.Title>{txt["title"]}</SC.Title>
      { isLoading && <Loader background={false} white={true} widthImage={{ desk: 90, tablet: 90, mobile: 90 }} textSize={{ desk: 16, tablet: 16, mobile: 16 }}/>}
      { !isLoading && Array.isArray(data) && data.length === 0 && <SC.NoMovie >{txt['error-NoData']}</SC.NoMovie>}
      { !isLoading && Array.isArray(data) && data.length !== 0 && <Slider loading={loading} refCont={movieCardContRef}>
        <SC.MovieCardCont ref={movieCardContRef} >
          { getMovieCard()} 
        </SC.MovieCardCont>
      </Slider>}
    </SC.Container>
  );
};
MovieCardList.propTypes = {
  sectionName: PropTypes.string.isRequired,
  external: PropTypes.bool,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
        href: PropTypes.string,
        slug: PropTypes.string
      })
    ),
    PropTypes.objectOf(PropTypes.string)
  ]),
  isLoading: PropTypes.bool,
  className: PropTypes.string
};

MovieCardList.defaultProps = {
  isLoading: false,
  className:''
};

export default MovieCardList;