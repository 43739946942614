import styled from "styled-components";

export const BtnCont = styled.div`
  margin-top: auto;
  & span {
    font-family: 'NotoSansSemiBold';
    font-size: 11px;
    color: #FFFFFF;
    background-color: #0070CC;
    border-radius: 5px;
    display: inline-block;
    text-transform: uppercase;
    padding: 14px 30px;
    transition: transform 0.1s, -webkit-transform 0.1s;
    transform: scale(0.98, 0.98);
    @media (min-width: 601px) and (max-width: 960px)  {
      padding: 10px;
      min-width: 103px
    }
    @media (min-width: 961px) {
      font-size: 14px;
      padding: 14px 30px;
    }
  }
`;

export const Container = styled.a`
  width: 100%;
  flex: 0 0 197px;
  text-align: center;
  margin: 0px 10px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  @media (min-width: 601px) and (max-width: 960px)  {
    flex: 0 0 160px;
  }
  @media (min-width: 961px) {
    flex: 0 0 250px;
    margin: 0px 15px;
  }
  text-decoration: none;
  &:hover ${BtnCont} span {
    background-image: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3));
    transform: translateZ(0) scale(1, 1);
  }
  &:hover img{
    transform: scale(1.03);
  }
`;

export const ContImg = styled.div`
  font-family: 'NotoSansSemiBold';
  font-size: 12px;
  line-height: 17px;
  color: #1D1E1F;
  overflow: hidden;
  box-sizing: border-box;
  & img{
    transition: transform 400ms, opacity 200ms;
    transform: scale(1);
    display: block;
    width: 100%;
  }
`;

export const ContDetails = styled.div`
  padding: 20px 10px 18px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  @media (min-width: 601px) {
    padding: 20px 10px 10px;
  }
  @media (min-width: 961px) {
    padding: 20px;
  }
`;

export const Title = styled.h2`
  margin: auto 0px 20px;
  font-family: 'NotoSansSemiBold';
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: #1D1E1F;
  @media (min-width: 961px) {
    font-size: 14px;
    line-height: 17px;
  }
`;